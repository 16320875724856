import { PowerData } from "@feature/power/powerConstants";
import {
  powerResetSlice,
  powerSetSlice,
} from "@feature/power/slice/powerSlice";
import { useAppDispatch } from "@core/redux/store";
import { useCallback } from "react";
import {
  usePowerCreateMutation,
  usePowerResetMutation,
} from "@feature/power/api/powerApi";

export const usePowerDataSetter = () => {
  const dispatch = useAppDispatch();

  const [ apiPowerCreate ] = usePowerCreateMutation();
  const [ apiPowerReset ] = usePowerResetMutation();

  const setPowerData = useCallback(({
    powerData,
    historySerieUuid = null,
    isDefault = true,
  }: {
    powerData: PowerData;
    historySerieUuid?: string;
    isDefault?: boolean;
  }) => {
    dispatch(powerSetSlice(powerData));
    apiPowerCreate({
      data: {
        Athlete: { connect: { uuid: powerData.athleteUuid } },
        Exercise: { connect: { uuid: powerData.exerciseUuid } },
        HistorySerie: { connect: { uuid: historySerieUuid } },
        loadWeight: powerData.loadWeight,
        powerReference: powerData.powerReference,
        isDefault: isDefault,
      },
    });
  }, [
    dispatch,
    apiPowerCreate,
  ]);

  const resetPowerData = useCallback((powerData: PowerData) => {
    dispatch(powerResetSlice(powerData));
    apiPowerReset({
      data: {
        athleteUuid: powerData.athleteUuid,
        exerciseUuid: powerData.exerciseUuid,
        loadWeight: powerData.loadWeight,
      },
    });
  }, [
    apiPowerReset,
    dispatch,
  ]);

  return {
    setPowerData: setPowerData,
    resetPowerData: resetPowerData,
  };
};
