import { PowerMetaData } from "@feature/power/powerConstants";
import { getPowerReferenceByPowerList } from "@feature/power/powerHelper";
import { selectPowerState } from "@feature/power/slice/powerSlice";
import { useAppSelector } from "@core/redux/store";
import { useMemo } from "react";
import { usePowerListQuery } from "@feature/power/api/powerApi";

export const usePowerDataGetter = (
  powerMetaData: PowerMetaData,
  options = {
    skip: false,
  }
) => {
  const powerState = useAppSelector(selectPowerState);

  const {
    data: apiPowerListData,
    ...restApiPowerListData
  } = usePowerListQuery({
    where: {
      athleteUuid: powerMetaData.athleteUuid,
      exerciseUuid: powerMetaData.exerciseUuid,
    },
  }, {
    skip: options.skip,
  });

  const powerData = useMemo(() => {
    const powerData = powerState.powerDataList.find(
      item =>
        item.athleteUuid === powerMetaData.athleteUuid &&
        item.exerciseUuid === powerMetaData.exerciseUuid &&
        item.loadWeight === powerMetaData.loadWeight
    );

    if (powerData) {
      return powerData;
    }

    // Get from apiPowerListData
    if (apiPowerListData) {
      const powerReference = getPowerReferenceByPowerList(apiPowerListData, powerMetaData.loadWeight);
      return {
        ...powerMetaData,
        powerReference: powerReference,
      };
    }

    return {
      ...powerMetaData,
      powerReference: 0,
    };
  }, [
    apiPowerListData,
    powerMetaData,
    powerState.powerDataList,
  ]);

  return {
    data: powerData,
    ...restApiPowerListData,
  };
};
