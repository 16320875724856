// To keep sync with Algorithm table
export const ALGORITHM_CODE_SOLO_CONCENTRIC = "SOLO_CONCENTRIC_MIN";
export const ALGORITHM_CODE_FUNCTIONAL_ISOMETRY = "FUNCTIONAL_ISOMETRY";
export const ALGORITHM_CODE_SLOW_SERIE = "SLOW_SERIE";
export const ALGORITHM_CODE_JUMP = "JUMP";
export const ALGORITHM_CODE_DYNAMIC_STATE_1T = "DYNAMIC_STATE_1T";
export const ALGORITHM_CODE_DYNAMIC_STATE_SP = "DYNAMIC_STATE_SP";
export const ALGORITHM_CODE_DYNAMIC_STATE_1T_JUMP = "DYNAMIC_STATE_1T_JUMP";
export const ALGORITHM_CODE_DYNAMIC_STATE_2T = "DYNAMIC_STATE_2T";

export type AlgorithmCode =
  | typeof ALGORITHM_CODE_SOLO_CONCENTRIC
  | typeof ALGORITHM_CODE_FUNCTIONAL_ISOMETRY
  | typeof ALGORITHM_CODE_SLOW_SERIE
  | typeof ALGORITHM_CODE_JUMP
  | typeof ALGORITHM_CODE_DYNAMIC_STATE_1T
  | typeof ALGORITHM_CODE_DYNAMIC_STATE_SP
  | typeof ALGORITHM_CODE_DYNAMIC_STATE_1T_JUMP
  | typeof ALGORITHM_CODE_DYNAMIC_STATE_2T
