import { PowerReferenceValue } from "@common/model/PowerReferenceValue";

export const API_CACHE_TAG_POWER = "power";
export const STORE_POWER_KEY = "power";

export const POWER_FORM_MIN_VALUE = 0;
export const POWER_FORM_MAX_VALUE = 9999;

export type PowerMetaData = {
  athleteUuid: string;
  exerciseUuid: string;
  loadWeight: number;
}

export type PowerData = PowerMetaData & {
  powerReference?: PowerReferenceValue;
}
