// @todo remove in favor of useConfiguration
import {
  CONFIGURATION_CODE_AUTOSTOP_TIME,
  CONFIGURATION_CODE_CONSECUTIVE_BAD_REPETITIONS_COUNT,
  CONFIGURATION_CODE_FEEDBACK_GO_TOLLERANCE_PERCENT,
  CONFIGURATION_CODE_KALMAN_Q,
  CONFIGURATION_CODE_KALMAN_R,
  CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL,
  CONFIGURATION_CODE_LOAD_WEIGHT_USED,
  CONFIGURATION_CODE_MAX_DISTANCE_TOO_FAR_FROM_MEDIAN_SPIKES,
  CONFIGURATION_CODE_MERGE_AFTER_PERCENT,
  CONFIGURATION_CODE_MERGE_ALMOST_ZERO_MAX_DISPLACEMENT,
  CONFIGURATION_CODE_MERGE_STALL_TIME_UNDER,
  CONFIGURATION_CODE_MERGE_TO_PERCENT,
  CONFIGURATION_CODE_POWER_MAX,
  CONFIGURATION_CODE_POWER_MIN,
  CONFIGURATION_CODE_POWER_REFERENCE_AUTO_UPDATE,
  CONFIGURATION_CODE_POWER_REFERENCE_USED,
  CONFIGURATION_CODE_RANGE_EXTENDED_READ_MAX_MM,
  CONFIGURATION_CODE_RANGE_EXTENDED_READ_MIN_MM,
  CONFIGURATION_CODE_RANGE_MAX_REDUCTION_MM,
  CONFIGURATION_CODE_RANGE_MAX_REDUCTION_PERCENT,
  CONFIGURATION_CODE_RANGE_MAX_USED,
  CONFIGURATION_CODE_RANGE_MIN_REDUCTION_MM,
  CONFIGURATION_CODE_RANGE_MIN_REDUCTION_PERCENT,
  CONFIGURATION_CODE_RANGE_MIN_USED,
  CONFIGURATION_CODE_READS_PER_SECONDS,
  CONFIGURATION_CODE_RECOVERY_TIME_INITIAL,
  CONFIGURATION_CODE_RECOVERY_TIME_MAX,
  CONFIGURATION_CODE_RECOVERY_TIME_MIN,
  CONFIGURATION_CODE_RECOVERY_TIME_USED,
  CONFIGURATION_CODE_SHUTUP_SECONDS,
  CONFIGURATION_CODE_SPEED_MAX,
  CONFIGURATION_CODE_SPEED_MIN,
  CONFIGURATION_CODE_SPEED_THRESHOLD_DOWN,
  CONFIGURATION_CODE_SPEED_THRESHOLD_UP,
  CONFIGURATION_CODE_USE_POWER,
  CONFIGURATION_CODE_USE_SPEED,
  CONFIGURTION_TYPE_BOOLEAN,
  CONFIGURTION_TYPE_DECIMAL,
  CONFIGURTION_TYPE_INTEGER,
  CONFIGURTION_TYPE_KILOGRAM,
  CONFIGURTION_TYPE_MILLIMETER,
  CONFIGURTION_TYPE_MILLIMETER_ON_MILLISECOND,
  CONFIGURTION_TYPE_MILLISECOND,
  CONFIGURTION_TYPE_NUMBER,
  CONFIGURTION_TYPE_PERCENTAGE,
  CONFIGURTION_TYPE_SECOND,
  CONFIGURTION_TYPE_WATT,
  ConfigurationCode,
} from "@common/model/Configuration";
import {
  Configuration,
  HistorySerieConfiguration,
  HistoryWorkoutConfiguration,
  RelationConfiguration,
  TrainingConfiguration,
} from "@common/type-graphql/generated";
import { ConfigurationValue } from "@common/model/ConfigurationValue";
import {
  DEVICE_RANGE_MAX,
  DEVICE_RANGE_MIN,
} from "@common/service/constants";
import { RangeMinMax } from "@common/model/Range";

export const getValueByRelationConfigurations = (
  relationConfigurations: RelationConfiguration[],
  configurationUuid: string
): string => {
  const relationConfiguration = relationConfigurations.find(relationConfiguration => relationConfiguration.configurationUuid === configurationUuid);
  return relationConfiguration?.value ?? "";
};

export const getRangeUsedByConfigurations = (configurations: ConfigurationValue[]): RangeMinMax | null => {
  const rangeUsedMin = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_MIN_USED);
  const rangeUsedMax = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_MAX_USED);

  if (rangeUsedMin && rangeUsedMax) {
    return {
      min: Number(rangeUsedMin.value),
      max: Number(rangeUsedMax.value),
    };
  }
  return null;
};

export const getRangeExtendedUsedByConfigurations = (configurations: ConfigurationValue[]): RangeMinMax | null => {
  const rangeUsedMin = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_MIN_USED);
  const rangeUsedMax = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_MAX_USED);

  if (rangeUsedMin && rangeUsedMax) {
    return {
      min: Number(rangeUsedMin.value),
      max: Number(rangeUsedMax.value),
    };
  }
  return null;
};

export const formatConfigurationValues = (
  configurations: Configuration[],
  configurationWithValues: TrainingConfiguration[] | HistoryWorkoutConfiguration[] | HistorySerieConfiguration[] | RelationConfiguration[]
): ConfigurationValue[] => {
  if (!configurations.length || !configurationWithValues.length) {
    return [];
  }
  return configurationWithValues.map(
    (configuration: TrainingConfiguration | HistoryWorkoutConfiguration | HistorySerieConfiguration | RelationConfiguration) =>
      getConfigurationCodeAndValueByUuid(configurations, configuration.configurationUuid, configuration.value?.toString())
  ) as ConfigurationValue[];
};

const getConfigurationCodeAndValueByUuid = (elements: Configuration[], uuid?: string, value?: string | null): ConfigurationValue => {
  if (!elements || uuid === undefined) {
    throw Error("Invalid configurations on getConfigurationCodeAndValueByUuid");
  }
  const element = elements.find(e => e.uuid === uuid);
  if (!element) {
    throw Error("Invalid configuration on getConfigurationCodeAndValueByUuid");
  }
  switch (element.unit) {
    case CONFIGURTION_TYPE_INTEGER:
    case CONFIGURTION_TYPE_PERCENTAGE:
    case CONFIGURTION_TYPE_WATT:
    case CONFIGURTION_TYPE_SECOND:
    case CONFIGURTION_TYPE_MILLISECOND:
    case CONFIGURTION_TYPE_MILLIMETER:
      return {
        code: element.code as ConfigurationCode,
        value: (value !== undefined && value !== null) ? Number.parseInt(value, 10) : null,
      };
    case CONFIGURTION_TYPE_MILLIMETER_ON_MILLISECOND:
    case CONFIGURTION_TYPE_KILOGRAM:
    case CONFIGURTION_TYPE_DECIMAL:
    case CONFIGURTION_TYPE_NUMBER:
      return {
        code: element.code as ConfigurationCode,
        value: (value !== undefined && value !== null) ? Number.parseFloat(value) : null,
      };
    case CONFIGURTION_TYPE_BOOLEAN:
      return {
        code: element.code as ConfigurationCode,
        value: value === "true",
      };
    default:
      return {
        code: element.code as ConfigurationCode,
        value: value,
      };
  }
};

export const getConfigurationByUuid = (elements: Configuration[], uuid: string): Configuration => {
  return elements.find(e => e.uuid === uuid) as Configuration;
};

export const getConfigurationUuidByCode = (elements: Configuration[], code: ConfigurationCode): string | null => {
  if (!elements) {
    return null;
  }
  const element = elements.find(e => e.code === code);
  return element?.uuid ?? null;
};

export const getConfigurationValueFormattedRecoveryTimeMin = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RECOVERY_TIME_MIN);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedRecoveryTimeMax = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RECOVERY_TIME_MAX);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedRecoveryTimeInitial = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RECOVERY_TIME_INITIAL);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedRecoveryTimeUsed = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RECOVERY_TIME_USED);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedAutoStopTime = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_AUTOSTOP_TIME);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedUsePower = (configurations: ConfigurationValue[]): boolean | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_USE_POWER);
  return configuration ? configuration.value as boolean : null;
};

export const getConfigurationValueFormattedPowerMin = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_POWER_MIN);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedPowerMax = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_POWER_MAX);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedUseSpeed = (configurations: ConfigurationValue[]): boolean | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_USE_SPEED);
  return configuration ? configuration.value as boolean : null;
};

export const getConfigurationValueFormattedSpeedMin = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_SPEED_MIN);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedSpeedMax = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_SPEED_MAX);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedKalmanQ = (configurations: ConfigurationValue[]): number => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_KALMAN_Q);
  return configuration ? parseFloat(configuration.value as string) : 0.07;
};

export const getConfigurationValueFormattedKalmanR = (configurations: ConfigurationValue[]): number => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_KALMAN_R);
  return configuration ? parseFloat(configuration.value as string) : 1;
};

export const getConfigurationValueFormattedConsecutiveBadRepetitionsCount = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_CONSECUTIVE_BAD_REPETITIONS_COUNT);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedShutupSeconds = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_SHUTUP_SECONDS);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedFeedbackGoTollerancePercent = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_FEEDBACK_GO_TOLLERANCE_PERCENT);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedLoadWeightInitial = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_LOAD_WEIGHT_INITIAL);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedLoadWeightUsed = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_LOAD_WEIGHT_USED);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedPowerReferenceUsed = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_POWER_REFERENCE_USED);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedPowerReferenceAutoUpdate = (configurations: ConfigurationValue[]): boolean | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_POWER_REFERENCE_AUTO_UPDATE);
  return configuration ? configuration.value as boolean : null;
};

export const getConfigurationValueFormattedRangeMinReductionMm = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_MIN_REDUCTION_MM);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedRangeMaxReductionMm = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_MAX_REDUCTION_MM);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedRangeMinReductionPercent = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_MIN_REDUCTION_PERCENT);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedRangeMaxReductionPercent = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_MAX_REDUCTION_PERCENT);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedMaxDistanceTooFarFromMedianSpikes = (configurations: ConfigurationValue[]): number => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_MAX_DISTANCE_TOO_FAR_FROM_MEDIAN_SPIKES);
  return configuration && configuration.value ? parseInt(configuration.value as string) : 50;
};

export const getConfigurationValueFormattedReadsPerSeconds = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_READS_PER_SECONDS);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedSpeedThresholdConcentric = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_SPEED_THRESHOLD_UP);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedSpeedThresholdEccentric = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_SPEED_THRESHOLD_DOWN);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedMergeStallTimeUnder = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_MERGE_STALL_TIME_UNDER);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedMergeAfterPercent = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_MERGE_AFTER_PERCENT);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedMergeToPercent = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_MERGE_TO_PERCENT);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedMergeAlmostZeroMaxDisplacement = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_MERGE_ALMOST_ZERO_MAX_DISPLACEMENT);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedRangeExtendedReadMinMm = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_EXTENDED_READ_MIN_MM);
  return configuration ? configuration.value as number : null;
};

export const getConfigurationValueFormattedRangeExtendedReadMaxMm = (configurations: ConfigurationValue[]): number | null => {
  const configuration = configurations.find(c => c.code === CONFIGURATION_CODE_RANGE_EXTENDED_READ_MAX_MM);
  return configuration ? configuration.value as number : null;
};

export const getRangeMinMaxExtended = (props: {
  range?: RangeMinMax | null;
  minExtensionMm?: number | null;
  maxExtensionMm?: number | null;
}): RangeMinMax | null => {
  if (!props.range) {
    return null;
  }

  let min = DEVICE_RANGE_MIN;
  if (props.minExtensionMm) {
    min = Math.max(min, props.range.min - props.minExtensionMm);
  }

  let max = DEVICE_RANGE_MAX;
  if (props.maxExtensionMm) {
    max = Math.min(max, props.range.max + props.maxExtensionMm);
  }

  return {
    min: min,
    max: max,
  };
};

export const getRangeMinMaxReduced = (props: {
  rangeMinMax?: RangeMinMax | null;
  minReductionMmPercent?: number | null;
  maxReductionMmPercent?: number | null;
  minReductionMm?: number | null;
  maxReductionMm?: number | null;
}): RangeMinMax | null => {
  if (!props.rangeMinMax) {
    return null;
  }

  if (
    !props.rangeMinMax.min ||
    !props.rangeMinMax.max
  ) {
    return props.rangeMinMax;
  }
  const mm = props.rangeMinMax.max - props.rangeMinMax.min;

  let min: number | undefined = props.rangeMinMax.min;
  if (
    props.minReductionMm &&
    props.minReductionMmPercent
  ) {
    min = Math.round(Math.max(props.rangeMinMax.min + props.minReductionMm, props.rangeMinMax.min + (mm * props.minReductionMmPercent / 100)));
  } else if (
    props.minReductionMm
  ) {
    min = Math.round(props.rangeMinMax.min + props.minReductionMm);
  } else if (
    props.minReductionMmPercent
  ) {
    min = Math.round(props.rangeMinMax.min + (mm * props.minReductionMmPercent / 100));
  }

  let max = props.rangeMinMax.max;
  if (
    props.maxReductionMm &&
    props.maxReductionMmPercent
  ) {
    max = Math.round(Math.min(props.rangeMinMax.max - props.maxReductionMm, props.rangeMinMax.max - (mm * props.maxReductionMmPercent / 100)));
  } else if (
    props.maxReductionMm
  ) {
    max = Math.round(props.rangeMinMax.max - props.maxReductionMm);
  } else if (
    props.maxReductionMmPercent
  ) {
    max = Math.round(props.rangeMinMax.max - (mm * props.maxReductionMmPercent / 100));
  }

  return {
    min: min,
    max: max,
  };
};

export const getConfigurationValueFormattedPowerMinCalculated = (
  relationConfigurations: ConfigurationValue[],
  powerMin: number
): number | null => {
  if (
    !relationConfigurations.length ||
    !powerMin
  ) {
    return null;
  }

  const powerMinPercent = getConfigurationValueFormattedPowerMin(relationConfigurations);
  if (!powerMinPercent) {
    return null;
  }
  return Math.round((powerMin / 100) * powerMinPercent);
};

export const getConfigurationValueFormattedPowerMaxCalculated = (
  relationConfigurations: ConfigurationValue[],
  powerMax: number
): number | null => {
  if (
    !relationConfigurations.length ||
    !powerMax
  ) {
    return null;
  }

  const powerMaxPercent = getConfigurationValueFormattedPowerMax(relationConfigurations);
  if (!powerMaxPercent) {
    return null;
  }
  return Math.round((powerMax / 100) * powerMaxPercent);
};
