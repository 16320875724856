import moment from "moment/moment";
import {
  BLUETOOTH_DEVICE_STATUS_CONNECTED,
  BLUETOOTH_DEVICE_STATUS_DISCONNECTED,
  DEVICE_BODYGON_FLOW_CHARACTERISTIC,
  DEVICE_BODYGON_GET_BATTERY_LEVEL_CHARACTERISTIC,
  DEVICE_BODYGON_SEND_CHARACTERISTIC,
  DEVICE_BODYGON_SERVICE,
  DEVICE_BODYGON_SET_NEW_NAME_CHARACTERISTIC,
  DEVICE_BODYGON_SET_RANGE_CHARACTERISTIC,
  DEVICE_BODYGON_SET_TEMPERATURE_CHARACTERISTIC,
  DEVICE_FAKE_ID,
  DEVICE_LAST_RECEIVED_THRESHOLD,
  DEVICE_NAME_PREFIX,
  DEVICE_NAME_PREFIX_CORE,
  DEVICE_NAME_PREFIX_PT,
  DEVICE_SCAN_TIMEOUT,
  STORE_DEVICE_KEY,
} from "@feature/device/deviceConstants";
import {
  BleClient,
  dataViewToNumbers,
  numbersToDataView,
  textToDataView,
} from "@capacitor-community/bluetooth-le";
import {
  BluetoothDevice,
  deviceDisconnect,
  deviceReadsReceived,
  deviceSlice,
  selectDeviceState,
} from "@feature/device/slice/deviceSlice";
import {
  DEVICE_RANGE_MAX,
  DEVICE_RANGE_MIN,
} from "@common/service/constants";
import { Dispatch } from "react";
import { Point } from "@common/model/Point";
import { RangeMinMax } from "@common/model/Range";
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { deviceReadsService } from "@feature/device/service/deviceReadsService";
import { deviceStubService } from "@feature/device/service/deviceStubService";
import { runCalibrationStop } from "@feature/run/slice/runCalibrationSlice";
import { runStopThunk } from "@feature/run/thunk/runThunk";
import { t } from "@lingui/macro";
import {
  toastUntilOk,
} from "@feature/toast/slice/toastSlice";

export let deviceScanTimeoutId: TimeoutId = null;
const deviceScanTimeout = (ms: number) => {
  return new Promise(resolve => {
    deviceScanTimeoutId = setTimeout(resolve, ms);
  });
};
const deviceScanSleep = async(ms: number, callback: any, ...args: any[]) => {
  await deviceScanTimeout(ms);
  return callback(...args);
};
export const deviceBleScanStartThunk = createAsyncThunk(
  `${ STORE_DEVICE_KEY }/bleScanStartThunk`,
  async(_, { dispatch }) => {
    try {
      await BleClient.initialize({ androidNeverForLocation: true });
    } catch (error) {
      console.error("Error during BLE initialization:", error);
    }

    try {
      await BleClient.requestLEScan({ allowDuplicates: true }, scanResult => {
        if (
          scanResult.device.name &&
          (
            scanResult.device.name.includes(DEVICE_NAME_PREFIX_PT) ||
            scanResult.device.name.includes(DEVICE_NAME_PREFIX_CORE) ||
            scanResult.device.name.includes(DEVICE_NAME_PREFIX)
          )
        ) {
          const device: BluetoothDevice = {
            name: scanResult.localName,
            id: scanResult.device.deviceId,
            rssi: scanResult.rssi,
            status: BLUETOOTH_DEVICE_STATUS_DISCONNECTED,
          };
          dispatch({
            type: deviceSlice.actions.addBleDevice.type,
            payload: device,
          });
        }
      });
    } catch (error) {
      console.error("Error during BLE scan:", error);
    }

    if (deviceScanTimeoutId) {
      clearTimeout(deviceScanTimeoutId);
    }

    await deviceScanSleep(DEVICE_SCAN_TIMEOUT, () => {
      dispatch(deviceBleScanStopThunk());
      deviceScanTimeoutId = null;
    }, []);
  }
);

export const deviceBleScanStopThunk = createAsyncThunk(
  `${ STORE_DEVICE_KEY }/bleScanStopThunk`,
  async() => {
    try {
      await BleClient.stopLEScan();
    } catch (error) {
      console.error("Error during BLE stop scan:", error);
    }
  }
);

const onDeviceBleDisconnect = (dispatch: Dispatch<any>, device: BluetoothDevice) => {
  dispatch(runStopThunk({ recoverAmount: 0 }));
  dispatch(runCalibrationStop());
  dispatch(deviceDisconnect(device));
  dispatch(toastUntilOk({
    message: t`Device disconnected.`,
    color: "warning",
  }));
};

export const deviceBleConnectByBrowserThunk = createAsyncThunk(
  `${ STORE_DEVICE_KEY }/bleConnectDeviceByBrowserThunk`,
  async(_, { dispatch }) => {
    try {
      await BleClient.initialize({ androidNeverForLocation: true });
    } catch (error) {
      console.error("Error during BLE initialization:", error);
    }
    try {
      const bleDevice = await BleClient.requestDevice({
        namePrefix: DEVICE_NAME_PREFIX,
        optionalServices: [ DEVICE_BODYGON_SERVICE ],
      });

      const device: BluetoothDevice = {
        id: bleDevice.deviceId,
        name: bleDevice.name,
        rssi: 0,
        status: BLUETOOTH_DEVICE_STATUS_CONNECTED,
      };

      await BleClient.connect(bleDevice.deviceId, () => {
        onDeviceBleDisconnect(dispatch, device);
      });

      return device;
    } catch (error) {
      console.error("Error during BLE request device:", error);
    }

    return null;
  }
);

export const deviceBleConnectThunk = createAsyncThunk<boolean, BluetoothDevice>(
  `${ STORE_DEVICE_KEY }/bleConnectThunk`,
  async(device, { dispatch }) => {
    try {
      await BleClient.connect(device.id, () => {
        onDeviceBleDisconnect(dispatch, device);
      });

      dispatch({
        type: deviceSlice.actions.deviceConnect.type,
        payload: device,
      });
    } catch (error) {
      console.error("Error during BLE connect:", error);
      return false;
    }
    return true;
  }
);

export const deviceBleSendStart = createAsyncThunk(
  `${ STORE_DEVICE_KEY }/bleSendStartThunk`,
  async(_, {
    getState,
    dispatch,
  }) => {
    const state: any = getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      deviceStubService.send(1);
      return;
    }

    try {
      await BleClient.startNotifications(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_FLOW_CHARACTERISTIC,
        dataView => {
          const dataArray = new Uint32Array(dataView.buffer);

          for (let i = 0; i < dataArray.length; i++) {
            const timeMs: number = dataArray[i] >>> 12;
            const distanceMm: number = dataArray[i] & 0xFFF;
            const payload: Point = {
              x: timeMs,
              y: distanceMm,
            };
            // console.info(`Incoming time_ms: ${ timeMs } - distance_mm: ${ distanceMm }`);

            if (timeMs !== 0) {
              deviceReadsService.add(payload);
            }
          }

          const now = Date.now();
          const state: any = getState();
          if (moment(now) > moment(state.device.lastReceived).add(DEVICE_LAST_RECEIVED_THRESHOLD, "milliseconds")) {
            dispatch(deviceReadsReceived());
          }
        }
      );
    } catch (error) {
      console.error("Error during startNotifications flow BLE:", error);
    }
    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SEND_CHARACTERISTIC,
        numbersToDataView([ 1 ])
      );
    } catch (error) {
      console.error("Error during write send start BLE:", error);
    }
  }
);

export const deviceBleSendStopThunk = createAsyncThunk(
  `${ STORE_DEVICE_KEY }/bleSendStopThunk`,
  async(_, { getState }) => {
    const state: any = getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      deviceStubService.send(0);
      return;
    }

    try {
      await BleClient.stopNotifications(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_FLOW_CHARACTERISTIC
      );
    } catch (error) {
      console.error("Error during stopNotifications flow BLE:", error);
    }

    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SEND_CHARACTERISTIC,
        numbersToDataView([ 0 ])
      );
    } catch (error) {
      console.error("Error during write send stop BLE:", error);
    }
  }
);

export const deviceBleSetRangeThunk = createAsyncThunk<void, RangeMinMax>(
  `${ STORE_DEVICE_KEY }/bleSetRangeThunk`,
  async(arg, { getState }) => {
    const min = Math.max(arg.min, DEVICE_RANGE_MIN);
    const max = Math.min(arg.max, DEVICE_RANGE_MAX);

    const byte1 = Math.floor(min < 256 ? 0 : min / 256);
    const byte2 = Math.floor((min < 256 ? min : min - (256 * byte1)));

    const byte3 = Math.floor((max < 256 ? 0 : max / 256));
    const byte4 = Math.floor((max < 256 ? max : max - (256 * byte3)));

    const state: any = getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      return;
    }

    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SET_RANGE_CHARACTERISTIC,
        numbersToDataView([
          byte1,
          byte2,
          byte3,
          byte4,
        ])
      );
    } catch (error) {
      console.error("Error during write range BLE:", error);
    }
  }
);

export const deviceBleSetTemperatureThunk = createAsyncThunk<void, { temperature: number }>(
  `${ STORE_DEVICE_KEY }/bleSetTemperatureThunk`,
  async(arg, { getState }) => {
    const state: any = getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      return;
    }

    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SET_TEMPERATURE_CHARACTERISTIC,
        numbersToDataView([ arg.temperature ])
      );
    } catch (error) {
      console.error("Error during write temperature BLE:", error);
    }
  }
);

export const deviceBleSetBatteryLevelThunk = createAsyncThunk(
  `${ STORE_DEVICE_KEY }/bleSetBatteryLevelThunk`,
  async(_, { getState }) => {
    const state: any = getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id === DEVICE_FAKE_ID) {
      return 0;
    }

    try {
      const batteryLevelData = await BleClient.read(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_GET_BATTERY_LEVEL_CHARACTERISTIC
      );
      return dataViewToNumbers(batteryLevelData)[0];
    } catch (error) {
      console.error("Error during read battery level BLE:", error);
    }
    return 0;
  }
);

export const deviceBleSetNewNameThunk = createAsyncThunk<void, {
  deviceId: string;
  newName: string;
}>(
  `${ STORE_DEVICE_KEY }/bleSetNewNameThunk`,
  async(arg, { getState }) => {
    const state: any = getState();
    const connectedDevice: BluetoothDevice = selectDeviceState(state).connectedDevice;

    if (connectedDevice.id !== arg.deviceId) {
      return;
    }

    try {
      await BleClient.write(
        connectedDevice.id,
        DEVICE_BODYGON_SERVICE,
        DEVICE_BODYGON_SET_NEW_NAME_CHARACTERISTIC,
        textToDataView(arg.newName)
      );
    } catch (error) {
      console.error("Error during write new name BLE:", error);
    }
  }
);
