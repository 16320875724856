import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import {
  IonCol,
  IonRow,
} from "@ionic/react";
import {
  POWER_FORM_MAX_VALUE,
  POWER_FORM_MIN_VALUE,
  PowerData,
} from "@feature/power/powerConstants";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import {
  Trans,
  t,
} from "@lingui/macro";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { usePowerDataSetter } from "@feature/power/hook/usePowerDataSetter";
import { usePowerLogic } from "@feature/power/hook/usePowerLogic";

type FormData = {
  value?: string;
}

type Props = {
  powerData: PowerData;
};

export const PowerForm = (props: Props) => {
  const { powerData } = props;
  const { setPowerData } = usePowerDataSetter();
  const { resetPowerConfirm } = usePowerLogic();

  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>({
    defaultValues: {
      value: powerData.powerReference ? powerData.powerReference.toString() : "",
    },
  });

  const onSubmit = async(formData: FormData) => {
    try {
      const value = parseInt(formData.value, 10);

      if (value > POWER_FORM_MIN_VALUE && value < POWER_FORM_MAX_VALUE) {
        setPowerData({
          powerData: {
            ...powerData,
            powerReference: value,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resetPowerSubmit = () => {
    resetPowerConfirm(powerData);
  };

  useEffect(() => {
    reset({
      value: powerData.powerReference ? powerData.powerReference.toString() : "",
    });
  }, [
    powerData.powerReference,
    reset,
  ]);

  return <>
    <FormUi onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <h3>
          <Trans>Power</Trans>
        </h3>
      </IonRow>
      <IonRow>
        <IonCol>
          <InputUi
            type="number"
            autoComplete={"off"}
            minValue={POWER_FORM_MIN_VALUE}
            maxValue={POWER_FORM_MAX_VALUE}
            isValid={!Boolean(errors.value)}
            id="value"
            placeholder={t`Value`}
            {...register("value", {
              required: true,
            })}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <PrimaryPositiveButtonUi
            formType={"submit"}
            isLoading={isSubmitting}
            label={t`Set Power`}
          />
        </IonCol>
        <IonCol>
          <PrimaryDestructiveButtonUi
            label={t`Reset Power`}
            onClick={resetPowerSubmit}
            disabled={isSubmitting || !Boolean(powerData.powerReference)}
          />
        </IonCol>
      </IonRow>
    </FormUi>
  </>;
};
