import {
  CALIBRATION_CODE_MAX,
  CALIBRATION_CODE_MIN,
  CALIBRATION_CODE_MIN_MAX_AUTO,
  CalibrationCode,
} from "@common/model/Calibration";
import {
  CalibrationReadPayload,
  RUN_CALIBRATION_PHASE_READING,
  runCalibrationAddRead,
  runCalibrationSetCalibrations,
  runCalibrationSetPhase,
  runCalibrationStop,
  selectRunCalibrationState,
} from "@feature/run/slice/runCalibrationSlice";
import {
  DEVICE_RANGE_MAX,
  DEVICE_RANGE_MIN,
  PHASE_MIN_DISPLACEMENT_WITHOUT_RANGE,
} from "@common/service/constants";
import { PHASE_IGNORE_REASON_TINY } from "@common/model/Phase";
import { STORE_RUN_CALIBRATION_KEY } from "@feature/run/runConstants";
import {
  audioPlayBeepWellDone,
  audioPlayCalibratioFailed,
  audioPlayCalibratioReading,
  audioPlayCalibrationMax,
  audioPlayCalibrationMin,
  audioPlayCalibrationMinMaxAuto,
  audioPlayRetry,
} from "@feature/audio/service/audioService";
import {
  calculateMinMaxAutoRange,
  extractStallPoint,
  ignoreShorterThanConcentricPhases,
} from "@common/service/serieService";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  currentSerie,
  resetCurrentSerie,
} from "@feature/run/data/currentSerie";
import { delay } from "@util/delay";
import { deviceBleSetRangeThunk } from "@feature/device/thunk/deviceThunk";
import { deviceReadsService } from "@feature/device/service/deviceReadsService";
import {
  runPrepeareThunk,
  runStartThunk,
  runStopThunk,
} from "@feature/run/thunk/runThunk";

type RunCalibrationStartThunkProps = {
  calibrationsCode: CalibrationCode[];
}

export const runCalibrationCalibrateInitThunk = createAsyncThunk<void, RunCalibrationStartThunkProps>(
  `${ STORE_RUN_CALIBRATION_KEY }/calibrateThunk`,
  async(args, {
    dispatch,
    getState,
  }) => {
    await dispatch(runPrepeareThunk());
    dispatch(runCalibrationSetCalibrations(args.calibrationsCode));
  }
);

export const runCalibrationCalibrateThunk = createAsyncThunk<CalibrationReadPayload[]>(
  `${ STORE_RUN_CALIBRATION_KEY }/calibrateThunk`,
  async(_, {
    dispatch,
    getState,
  }) => {
    await dispatch(performCalibrationsThunk());

    deviceReadsService.clear();
    resetCurrentSerie();

    const state: any = getState();
    const runCalibrationState = selectRunCalibrationState(state);
    return runCalibrationState.runCalibrationReads;
  }
);

// This is called to start the calibration process for each calibration
const performCalibrationsThunk = createAsyncThunk<boolean>(
  `${ STORE_RUN_CALIBRATION_KEY }/performCalibrationsThunk`,
  async(code, {
    dispatch,
    getState,
  }) => {
    let state: any = getState();
    let runCalibrationState = selectRunCalibrationState(state);

    // Check if the calibration process is active
    if (!runCalibrationState.isCalibrating) {
      return false;
    }

    // Check if there are calibrations to perform
    const calibrationsCode = runCalibrationState.runCalibrations;
    if (calibrationsCode.length === 0) {
      dispatch(runStopThunk({ recoverAmount: 0 }));
      dispatch(runCalibrationStop());
      return false;
    }

    // Cycle all calibrations with retry mechanism
    for (let i = 0; i < calibrationsCode.length; i++) {
      let retryCount = 0;
      let success = false;
      while (retryCount < 3 && !success) {
        state = getState();
        runCalibrationState = selectRunCalibrationState(state);
        if (!runCalibrationState.isCalibrating) {
          return false;
        }
        if (retryCount > 0) {
          await audioPlayRetry();
        }
        const min = runCalibrationState.runCalibrationReads.find(c => c.code === CALIBRATION_CODE_MIN);
        const rangeMinMax = {
          min: DEVICE_RANGE_MIN,
          max: DEVICE_RANGE_MAX,
        };
        if (min) {
          rangeMinMax.min = min.value;
        }
        dispatch(deviceBleSetRangeThunk(rangeMinMax));
        const result = await dispatch(performCalibrationThunk(calibrationsCode[i]));
        if (result.payload === false) {
          retryCount++;
        } else {
          success = true;
        }
      }
      if (!success) {
        audioPlayCalibratioFailed();
        dispatch(runStopThunk({
          recoverAmount: 0,
        }));
        dispatch(runCalibrationStop());
        return false;
      }
    }

    state = getState();
    runCalibrationState = selectRunCalibrationState(state);

    return runCalibrationState.runCalibrationReads.length > 0;
  }
);

const performCalibrationThunk = createAsyncThunk<boolean, CalibrationCode>(
  `${ STORE_RUN_CALIBRATION_KEY }/performCalibrationThunk`,
  async(calibrationCode, {
    dispatch,
    getState,
  }) => {
    let state: any = getState();
    let runCalibrationState = selectRunCalibrationState(state);

    // Check if the calibration process is active
    if (!runCalibrationState.isCalibrating) {
      return false;
    }

    switch (calibrationCode) {
      case CALIBRATION_CODE_MIN:
        await audioPlayCalibrationMin();
        break;
      case CALIBRATION_CODE_MAX:
        await audioPlayCalibrationMax();
        break;
      case CALIBRATION_CODE_MIN_MAX_AUTO:
        await audioPlayCalibrationMinMaxAuto();
        break;
    }

    dispatch(runCalibrationSetPhase(RUN_CALIBRATION_PHASE_READING));

    dispatch(runStartThunk({
      wait: 0,
    }));

    let currentPhase = null;
    let trip = 0;
    do {
      trip++;
      audioPlayCalibratioReading();
      await delay(1000);

      if (calibrationCode === CALIBRATION_CODE_MIN_MAX_AUTO) {
        ignoreShorterThanConcentricPhases(currentSerie.mergedPhases, PHASE_MIN_DISPLACEMENT_WITHOUT_RANGE, PHASE_IGNORE_REASON_TINY);
        const minMaxAutoRange = calculateMinMaxAutoRange(currentSerie.mergedPhases);
        if (minMaxAutoRange) {
          dispatch(runCalibrationAddRead({
            code: CALIBRATION_CODE_MIN,
            value: minMaxAutoRange.min,
          }));
          dispatch(runCalibrationAddRead({
            code: CALIBRATION_CODE_MAX,
            value: minMaxAutoRange.max,
          }));
          await audioPlayBeepWellDone();
          break;
        }
      } else {
        const calibrationValue = extractStallPoint(currentSerie, 3000);
        if (calibrationValue) {
          dispatch(runCalibrationAddRead({
            code: runCalibrationState.runCalibrations[0],
            value: calibrationValue,
          }));
          await audioPlayBeepWellDone();
          break;
        }
      }

      if (trip > 20) {
        return false;
      }

      state = getState();
      runCalibrationState = selectRunCalibrationState(state);
      currentPhase = runCalibrationState.runCalibrationPhase;
    } while (currentPhase === RUN_CALIBRATION_PHASE_READING);

    if (!runCalibrationState.isCalibrating) {
      return false;
    }

    dispatch(runStopThunk({
      recoverAmount: 0,
    }));
    return true;
  }
);
