import { PowerData } from "@feature/power/powerConstants";
import { confirm } from "@feature/confirm/service/confirm";
import { t } from "@lingui/macro";
import { useCallback } from "react";
import { useIonActionSheet } from "@ionic/react";
import { usePowerDataSetter } from "@feature/power/hook/usePowerDataSetter";

export const usePowerLogic = () => {
  const [ present ] = useIonActionSheet();
  const { resetPowerData } = usePowerDataSetter();

  const resetPowerConfirm = useCallback((powerData: PowerData) => {
    if (
      !powerData.athleteUuid ||
      !powerData.exerciseUuid
    ) {
      return new Promise<PowerData>(resolve => false);
    }

    return new Promise<boolean>((resolve, reject) => {
      confirm({
        header: t`Do you want to reset your power reference?`,
        subHeader: t`Your new power reference will be calculated in the next serie`,
        present: present,
        action: () => {
          resetPowerData(powerData);
          resolve(true);
        },
      });
    });
  }, [
    present,
    resetPowerData,
  ]);

  return {
    resetPowerConfirm: resetPowerConfirm,
  };
};
