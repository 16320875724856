import {
  DEVICE_RANGE_MAX,
  DEVICE_RANGE_MIN,
} from "@common/service/constants";
import { FormUi } from "@ui/FormUi/FormUi";
import { InputUi } from "@ui/InputUi/InputUi";
import {
  IonCol,
  IonRow,
  useIonActionSheet,
} from "@ionic/react";
import { PrimaryDestructiveButtonUi } from "@ui/PrimaryDestructiveButtonUi/PrimaryDestructiveButtonUi";
import { PrimaryPositiveButtonUi } from "@ui/PrimaryPositiveButtonUi/PrimaryPositiveButtonUi";
import { RangeMinMax } from "@common/model/Range";
import {
  Trans,
  t,
} from "@lingui/macro";
import { confirmDelete } from "@feature/confirm/service/delete";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { usePowerLogic } from "@feature/power/hook/usePowerLogic";
import { useRange } from "@feature/range/hook/useRange";

type FormData = {
  min?: string;
  max?: string;
}

type Props = {
  athleteUuid: string;
  protocolUuid: string;
  exerciseUuid: string;
  loadWeight: number;
  range?: RangeMinMax;
}

export const RangeFormComponent = (props: Props) => {
  const [ present ] = useIonActionSheet();
  const { resetPowerConfirm } = usePowerLogic();
  const { setRange } = useRange();

  const {
    reset,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<FormData>({
    defaultValues: {
      min: props.range ? props.range.min.toString() : DEVICE_RANGE_MIN.toString(),
      max: props.range ? props.range.max.toString() : DEVICE_RANGE_MAX.toString(),
    },
  });

  const onSubmit = async(formData: FormData) => {
    try {
      const rangeMinMax: RangeMinMax = {
        min: parseInt(formData.min, 10),
        max: parseInt(formData.max, 10),
      };
      setRange({
        athleteUuid: props.athleteUuid,
        protocolUuid: props.protocolUuid,
        exerciseUuid: props.exerciseUuid,
        rangeMinMax: rangeMinMax,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const resetRangeSubmit = () => {
    confirmDelete({
      header: t`Do you want to reset the range?`,
      subHeader: t`This action will reset the range to the default value.`,
      present: present,
      action: async() => {
        resetPowerConfirm({
          athleteUuid: props.athleteUuid,
          exerciseUuid: props.exerciseUuid,
          loadWeight: props.loadWeight,
        });
        // move into resetPowerConfirm
        setRange({
          athleteUuid: props.athleteUuid,
          protocolUuid: props.protocolUuid,
          exerciseUuid: props.exerciseUuid,
          rangeMinMax: null,
        });
      },
    });
  };

  useEffect(() => {
    // Questo effetto si attiva ogni volta che cambia `props.range`
    reset({
      min: props.range ? props.range.min.toString() : DEVICE_RANGE_MIN.toString(),
      max: props.range ? props.range.max.toString() : DEVICE_RANGE_MAX.toString(),
    });
  }, [
    props.range,
    reset,
  ]);

  return <>
    <FormUi onSubmit={handleSubmit(onSubmit)}>
      <>
        <IonRow>
          <h3>
            <Trans>Range</Trans>
          </h3>
        </IonRow>
        <IonRow>
          <IonCol>
            <InputUi
              type="number"
              autoComplete={"off"}
              minValue={DEVICE_RANGE_MIN}
              maxValue={DEVICE_RANGE_MAX}
              isValid={!Boolean(errors.min)}
              id="min"
              placeholder={t`Min`}
              {...register("min", {
                required: true,
              })}
            />
          </IonCol>

          <IonCol>
            <InputUi
              type="number"
              autoComplete={"off"}
              minValue={DEVICE_RANGE_MIN}
              maxValue={DEVICE_RANGE_MAX}
              isValid={!Boolean(errors.max)}
              id="max"
              placeholder={t`Max`}
              {...register("max", {
                required: true,
              })}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <PrimaryPositiveButtonUi
              formType={"submit"}
              isLoading={isSubmitting}
              label={t`Set Range`}
            />
          </IonCol>
          <IonCol>
            <PrimaryDestructiveButtonUi
              label={t`Reset Range`}
              onClick={resetRangeSubmit}
              disabled={isSubmitting || !Boolean(props.range)}
            />
          </IonCol>
        </IonRow>
      </>
    </FormUi>
  </>;
};
