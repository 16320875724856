import { PowerData } from "@feature/power/powerConstants";
import { Serie } from "@common/model/Serie";
import { calculateMaxPower } from "@common/service/serieService";
import { usePowerDataSetter } from "@feature/power/hook/usePowerDataSetter";

export const useRunAutoUpdatePowerReference = () => {
  const { setPowerData } = usePowerDataSetter();

  const checkAutoUpdatePowerReference = (props: {
    serie: Serie;
    powerData: PowerData;
  }) => {
    const maxPower = calculateMaxPower(props.serie);

    if (
      !maxPower ||
      maxPower <= props.powerData.powerReference
    ) {
      return;
    }

    const powerData: PowerData = {
      athleteUuid: props.powerData.athleteUuid,
      exerciseUuid: props.powerData.exerciseUuid,
      loadWeight: props.powerData.loadWeight,
      powerReference: maxPower,
    };

    setPowerData({
      powerData: powerData,
    });
  };

  return {
    runCheckAutoUpdatePowerReference: checkAutoUpdatePowerReference,
  };
};
