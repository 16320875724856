/* eslint-disable no-magic-numbers,no-case-declarations */
import {
  DENSITY_MIN_POINTS_PER_SECOND,
  MS_AROUND_TO_CALCULATE_MAX,
  SIMPLIFIED_MAX_DELAY_BETWEEN_POINTS,
} from "./constants";
import {
  DIRECTION_ASCENDING,
  DIRECTION_DESCENDING,
  DIRECTION_INVALID,
  DIRECTION_STABLE,
  Direction,
} from "../model/Direction";
import { Point } from "../model/Point";
import { RangeMinMax } from "@common/model/Range";

export const calculateDirectionBySpeed = (
  speeds: number[],
  currentIndex: number,
  thresholdUp: number,
  thresholdDown?: number
): Direction => {
  const speed = speeds[currentIndex];
  if (speed > thresholdUp) {
    return DIRECTION_ASCENDING;
  } else if (
    thresholdDown !== undefined &&
    speed < -thresholdDown
  ) {
    return DIRECTION_DESCENDING;
  }
  return DIRECTION_STABLE;
};

/*
 * @returns median (mm)
 */
export const getMedian = (points: Point[]): number => {
  const yValues = points.map(point => point.y);
  let median = 0;

  const sortedArray = yValues.slice().sort((a, b) => a - b);
  const middleIndex = Math.floor(sortedArray.length / 2);

  if (sortedArray.length % 2 === 0) {
    median = (sortedArray[middleIndex - 1] + sortedArray[middleIndex]) / 2;
  } else {
    median = sortedArray[middleIndex];
  }

  return Math.round(median);
};

/*
 * @returns density (reads * second)
 */
export const getDensity = (points: Point[], maxReadsPerSecond: number, msBefore: number, msAhead: number): number => {
  const around = msBefore + msAhead;
  const totalPoints = points.length - 1;
  const maxReads = around / (1000 / maxReadsPerSecond);
  const pointsPerSecond = (totalPoints / maxReads) * 100;
  return Math.min(maxReadsPerSecond, Math.floor(pointsPerSecond));
};

export const calculateMedian = (points: Point[], currentIndex: number, paddingLimitMs: number): number => {
  const pointsFrom = findBackward(points, paddingLimitMs, currentIndex);
  const pointsTo = findForward(points, paddingLimitMs, currentIndex);
  const medianPoints = points.slice(pointsFrom, pointsTo);
  return getMedian(medianPoints);
};

export const calculateDensityPoint = (points: Point[], currentIndex: number, maxReadsPerSecond: number, msBefore: number, msAhead: number): number => {
  const pointFrom = findBackward(points, msBefore, currentIndex);
  // First reads, return max density
  if (points[pointFrom].x < msBefore) {
    return maxReadsPerSecond;
  }
  const pointTo = findForward(points, msAhead, currentIndex);
  const densityPoints = points.slice(pointFrom, pointTo);
  return getDensity(densityPoints, maxReadsPerSecond, msBefore, msAhead);
};

export const calculateSpeedPoint = (points: Point[], currentIndex: number, paddingLimitMs: number): number => {
  if (currentIndex === 0) {
    return 0;
  }
  const pointsFrom = findBackward(points, paddingLimitMs, currentIndex);
  const pointsTo = findForward(points, paddingLimitMs, currentIndex);
  const deltaDistance = points[pointsTo].y - points[pointsFrom].y;
  const deltaTime = (points[pointsTo].x - points[pointsFrom].x);
  const speed = deltaTime === 0 ? 0 : deltaDistance / deltaTime;
  return Number(speed.toFixed(5));
};

/* @param radix (divider) */
export const round = (points: Point[], radix = 10): Point[] => {
  points.map(p => {
    p.y = Math.round(p.y / radix) * radix;
    return p;
  });
  return points;
};

export const getFromAndToIndexByIndexes = (
  points: Point[],
  lastCalculatedIndex: number,
  paddingLimitMs: number
): { fromIndex: number; toIndex: number } => {
  let fromIndex = lastCalculatedIndex;
  if (fromIndex > 0) {
    fromIndex = fromIndex + 1;
  }
  const toMs = points[points.length - 1].x - paddingLimitMs;
  const toIndex = getIndexByMs(points, toMs);
  if (toIndex <= lastCalculatedIndex) {
    return {
      fromIndex: -1,
      toIndex: -1,
    };
  }
  return {
    fromIndex: fromIndex,
    toIndex: toIndex,
  };
};

export const getIndexByMs = (points: Point[], ms: number): number => {
  if (ms < 0) {
    throw Error(`Invalid ms in getIndexByMs: ${ ms }`);
  }
  const index = points.findIndex(p => p.x > ms);
  if (index === -1) {
    throw Error(`Index not found in getIndexByMs with ms:${ ms } and points:${ JSON.stringify(points) }`);
  }
  return index - 1;
};

export const findBackward = (points: Point[], paddingLimitMs: number, indexFromInput?: number): number => {
  if (indexFromInput && (indexFromInput < 0 || indexFromInput >= points.length)) {
    throw Error(`Invalid index in findBackward, indexFrom: ${ indexFromInput }`);
  }
  const startIndex = (typeof indexFromInput === "undefined") ? points.length - 1 : indexFromInput;
  const pivot = points[startIndex];
  const limit = pivot.x - paddingLimitMs;
  if (limit <= 0) {
    return 0;
  }
  let index = points.findIndex(p => p.x >= limit);
  index--;
  if (index <= -1) {
    index = 0;
  }
  return index;
};

/**
 * Interpolates a new x value for a given y, based on two existing points.
 * @param x1 - The x value of the first point
 * @param y1 - The y value of the first point
 * @param x2 - The x value of the second point
 * @param y2 - The y value of the second point
 * @param y - The y value for which to calculate the new x
 * @returns The interpolated x value for the given y
 */
export const interpolateX = (x1: number, y1: number, x2: number, y2: number, y: number): number => {
  if (y1 === y2) {
    return x1; // or return x2, as they are effectively at the same point on the y-axis
  }

  const slope = (x2 - x1) / (y2 - y1);
  return Math.round(x1 + slope * (y - y1));
};

/**
 * Interpolates a new y value for a given x, based on two existing points.
 * @param x1 - The x value of the first point
 * @param y1 - The y value of the first point
 * @param x2 - The x value of the second point
 * @param y2 - The y value of the second point
 * @param x - The x value for which to calculate the new y
 * @returns The interpolated y value for the given x
 */
export const interpolateY = (x1: number, y1: number, x2: number, y2: number, x: number): number => {
  if (x1 === x2) {
    return y1; // or return y2, as they are effectively at the same point on the x-axis
  }

  const slope = (y2 - y1) / (x2 - x1);
  return Math.round(y1 + slope * (x - x1));
};

export const findIndexBackwardLowerTillMm = (
  refined: number[],
  limitMm: number,
  startFromIndex: number,
  limitIndex: number
): number => {
  if (startFromIndex < 0 || startFromIndex >= refined.length) {
    throw Error(`Invalid index in findIndexBackwardTillMm, indexFrom: ${ startFromIndex }`);
  }
  let index = startFromIndex;

  while (
    index - 2 >= 0 &&
    refined[index - 1] >= limitMm &&
    index - 1 > limitIndex
  ) {
    index--;
  }

  return index;
};

export const findIndexBackwardHigherTillMm = (
  refined: number[],
  limitMm: number,
  startFromIndex: number,
  limitIndex: number
): number => {
  if (startFromIndex < 0 || startFromIndex >= refined.length) {
    throw Error(`Invalid index in findIndexBackwardTillMm, indexFrom: ${ startFromIndex }`);
  }
  let index = startFromIndex;

  while (
    index - 2 >= 0 &&
    refined[index - 1] <= limitMm &&
    index - 1 > limitIndex
  ) {
    index--;
  }

  return index;
};

export const findIndexForwardLowerTillMm = (
  refined: number[],
  limitMm: number,
  startFromIndex: number,
  limitIndex: number
): number => {
  if (startFromIndex < 0 || startFromIndex >= refined.length) {
    throw Error(`Invalid index in findIndexForwardTillMm, indexFrom: ${ startFromIndex }`);
  }
  let index = startFromIndex;

  while (
    index + 2 < refined.length &&
    refined[index + 1] >= limitMm &&
    index + 1 < limitIndex
  ) {
    index++;
  }

  return index;
};

export const findIndexForwardHigherTillMm = (
  refined: number[],
  limitMm: number,
  startFromIndex: number,
  limitIndex: number
): number => {
  if (startFromIndex < 0 || startFromIndex >= refined.length) {
    throw Error(`Invalid index in findIndexForwardTillMm, indexFrom: ${ startFromIndex }`);
  }
  let index = startFromIndex;

  while (
    index + 2 < refined.length &&
    refined[index + 1] <= limitMm &&
    index + 1 < limitIndex
  ) {
    index++;
  }

  return index;
};

export const findForward = (points: Point[], paddingLimitMs: number, startIndex: number): number => {
  const indexFrom = startIndex > -1 ? startIndex : 0;
  if (indexFrom >= points.length) {
    throw Error(`Invalid index in findForward, startIndex: ${ startIndex } and points lenght: ${ points.length }`);
  }

  const pointsLimit = points.length - 1;
  if (indexFrom === pointsLimit) {
    return pointsLimit;
  }
  const pivot = points[indexFrom];
  const last = points[pointsLimit];
  const lastX = last.x;
  const limit = Math.min(lastX, pivot.x + paddingLimitMs - 1);
  if (limit === lastX) {
    return pointsLimit;
  }
  let currentIndex = indexFrom + 1;

  while (
    currentIndex < pointsLimit &&
        points[currentIndex].x < limit
  ) {
    currentIndex++;
  }
  return currentIndex;
};

export const isTooFarByPoint = (value: number, mm: number, mmLimit: number): boolean => {
  const min = Math.min(value, mm);
  const max = Math.max(value, mm);
  return (max - min) > mmLimit;
};

export const thereAreEnoughPoints = (points: Point[], lastCalculateIndex: number): boolean => {
  const pointsLength = points.length;
  if (pointsLength === 0 || lastCalculateIndex < 0) {
    return false;
  }
  const lastPoint = points[pointsLength - 1];
  const lastX = lastPoint.x;

  const lastCalculatedPoint = points[lastCalculateIndex];

  if (!lastCalculatedPoint) {
    return lastX > MS_AROUND_TO_CALCULATE_MAX;
  }
  const lastCalaculatedX = lastCalculatedPoint.x;
  return (lastX - lastCalaculatedX) > MS_AROUND_TO_CALCULATE_MAX;
};

export const getPower = (displacement: number, duration: number, weight: number) => {
  return Math.floor((weight * displacement * 9.81) / duration);
};

export const getRefining = (
  originalPoints: Point[],
  refined: number[],
  lastCalculatedIndex: number
): number[] => {
  if (lastCalculatedIndex === 0 && refined.length === 0) {
    return JSON.parse(JSON.stringify(originalPoints.map(point => point.y)));
  }
  const newRefinedPoints = refined.slice(0, lastCalculatedIndex);
  const newFinalPointsIndex = originalPoints.slice(lastCalculatedIndex).map(point => point.y);
  return JSON.parse(JSON.stringify(newRefinedPoints.concat(newFinalPointsIndex)));
};

export const fixSpikePoint = (
  value: number,
  reference: number,
  maxDistanceMm: number
) => {
  if (isTooFarByPoint(value, reference, maxDistanceMm)) {
    // console.log("Fixing spike point", value, reference);
    return reference;
  }
  return value;
};

export const invalidateOutOfDensityDirections = (
  directionPoints: Direction[],
  densities: number[],
  fromIndex: number,
  readsPerSecond: number
): void => {
  for (let i = fromIndex; i < directionPoints.length; i++) {
    const density = densities[i];
    const newDirectionPoint = directionPoints[i];
    const densityPercentage = (density / readsPerSecond) * 100;
    if (densityPercentage < DENSITY_MIN_POINTS_PER_SECOND) {
      if (
        newDirectionPoint === DIRECTION_ASCENDING ||
        newDirectionPoint === DIRECTION_DESCENDING
      ) {
        directionPoints[i] = DIRECTION_INVALID;
        i++;
        while (
          i < directionPoints.length &&
          directionPoints[i] !== DIRECTION_STABLE
        ) {
          directionPoints[i] = DIRECTION_INVALID;
          i++;
        }
        i--;
      } else if (newDirectionPoint === DIRECTION_STABLE) {
        directionPoints[i] = DIRECTION_INVALID;
      }
    }
  }
};

export const calculateSimplified = (
  finalPoints: Point[],
  simplifiedPoints: Point[],
  directionPoints: Direction[],
  fromIndex: number,
  toIndex: number,
  range?: RangeMinMax | null
): Point[] => {
  const newSimplifiedPoints: Point[] = simplifiedPoints;
  let previousDirectionPoint = directionPoints[fromIndex];
  for (let currentIndex = fromIndex; currentIndex < toIndex; currentIndex++) {
    if (currentIndex === 0) {
      newSimplifiedPoints.push(finalPoints[currentIndex]);
      continue;
    } else if (currentIndex + 1 === toIndex) {
      newSimplifiedPoints.push(finalPoints[currentIndex]);
      continue;
    }

    if (!(currentIndex in directionPoints)) {
      return simplifiedPoints;
    }

    const currentDirectionPoint = directionPoints[currentIndex];
    const currentFinal = finalPoints[currentIndex].y;
    const lastSimplifiedPoint = newSimplifiedPoints[newSimplifiedPoints.length - 1];
    const currentOriginalPoint = finalPoints[currentIndex];

    if (currentDirectionPoint !== previousDirectionPoint) {
      newSimplifiedPoints.push({
        x: currentOriginalPoint.x,
        y: currentFinal,
      });
    } else if (
      range && (
        currentFinal === range.min ||
        currentFinal === range.max
      )
    ) {
      newSimplifiedPoints.push({
        x: finalPoints[currentIndex].x,
        y: currentFinal,
      });
    } else if (currentOriginalPoint.x >= (lastSimplifiedPoint.x + SIMPLIFIED_MAX_DELAY_BETWEEN_POINTS)) {
      newSimplifiedPoints.push({
        x: finalPoints[currentIndex].x,
        y: currentFinal,
      });
    }

    previousDirectionPoint = directionPoints[currentIndex];
  }

  return newSimplifiedPoints;
};

export const getDisplacement = (
  point1: Point,
  point2: Point
): number => {
  return Math.abs(point1.y - point2.y);
};

export const getDuration = (
  point1: Point,
  point2: Point
): number => {
  return Math.abs(point1.x - point2.x);
};

export const getSpeed = (
  point1: Point,
  point2: Point
): number => {
  return getDisplacement(point1, point2) / getDuration(point1, point2);
};

/**
 * Calculates the margins for a given range with dynamic margin percentages based on the displacement.
 * The function takes an object representing a range with minimum and maximum values and parameters
 * for defining the margin percentages and thresholds.
 * It then calculates the displacement (difference) between these values to determine
 * appropriate margins based on the size of the range.
 *
 * @param {RangeMinMax} rangeMinMax - The range object with min and max values.
 * @param {number} lowDisplacementMargin - The margin percentage for displacements up to the lower threshold.
 * @param {number} highDisplacementMargin - The margin percentage for displacements equal to or greater than the upper threshold.
 * @param {number} lowerThreshold - The displacement threshold for the lower margin percentage.
 * @param {number} upperThreshold - The displacement threshold for the higher margin percentage and the linear decrease starts.
 *
 * @returns An array with the new maximum and minimum values after applying the margins.
 */
export const getRangeMargins = (
  rangeMinMax: RangeMinMax,
  lowDisplacementMargin: number,
  highDisplacementMargin: number,
  lowerThreshold: number,
  upperThreshold: number
) => {
  const displacement = rangeMinMax.max - rangeMinMax.min;

  let marginPercent = lowDisplacementMargin;

  if (displacement > lowerThreshold && displacement < upperThreshold) {
    // Calculate the linear decrease from lowDisplacementMargin to highDisplacementMargin
    const slope = (highDisplacementMargin - lowDisplacementMargin) / (upperThreshold - lowerThreshold);
    marginPercent = slope * (displacement - lowerThreshold) + lowDisplacementMargin;
  } else if (displacement >= upperThreshold) {
    marginPercent = highDisplacementMargin;
  }

  const margin = Math.round((displacement / 100) * marginPercent);

  return [
    rangeMinMax.max - margin,
    rangeMinMax.min + margin,
  ];
};

export function trimPoints(
  points: Point[],
  startValue: number | null,
  endValue: number | null,
  ms = 500
): Point[] {
  function findClosestIndex(value: number): number {
    let low = 0;
    let high = points.length - 1;
    while (low < high) {
      const mid = Math.floor((low + high) / 2);
      if (points[mid].x < value) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }
    return low;
  }

  if (startValue === null || endValue === null) {
    throw new Error("Both startValue and endValue must be provided and non-null");
  }
  if (startValue >= endValue) {
    throw new Error("startValue must be less than endValue");
  }

  const startMargin = startValue - ms;
  const endMargin = endValue + ms;

  const startIndex = findClosestIndex(startMargin);
  const endIndex = findClosestIndex(endMargin);

  const trimmedPoints = points.slice(startIndex, endIndex + 1);

  const baseX = trimmedPoints.length > 0 ? trimmedPoints[0].x : 0;
  return trimmedPoints.map(point => ({
    x: point.x - baseX,
    y: point.y,
  }));
}

export const calculateIsInStall = (
  points: Point[],
  maxDisplacementMm: number,
  stallTimeMs: number
) => {
  let isInStall = true;
  let pointsAreEnought = false;
  let displacement = 0;
  const last = points.length - 1;
  let i = last;
  let j = last;

  while (!pointsAreEnought && j > 0) {
    const duration = getDuration(points[i], points[j]);
    if (duration > stallTimeMs) {
      pointsAreEnought = true;
      break;
    }
    j--;
  }

  if (!pointsAreEnought) {
    return false;
  }

  while (isInStall && i > j) {
    i--;
    displacement = getDisplacement(points[i], points[last]);
    if (displacement > maxDisplacementMm) {
      isInStall = false;
      break;
    }
  }

  return isInStall;
};
