import {
  Algorithm,
  Athlete,
  Configuration,
  Exercise,
  HistorySerie,
  Profile,
  Protocol,
  Relation,
} from "@common/type-graphql/generated";
import { ApiData } from "@feature/api/component/ApiData";
import { AthleteChipComponent } from "@feature/athlete/component/athleteChipComponent";

import { ChartsComponent } from "@feature/run/component/ChartsComponent";
import { ChipMultipleUi } from "@ui/ChipMultipleUi/ChipMultipleUi";
import { ConfigurationValue } from "@common/model/ConfigurationValue";
import { DetailsPhasesComponent } from "@feature/serie/component/detailsPhasesComponent";
import { ExerciseChipComponent } from "@feature/exercise/component/exerciseChipComponent";
import { IonIcon } from "@ionic/react";
import { PrimaryButtonUi } from "@ui/PrimaryButtonUi/PrimaryButtonUi";
import { ProfileChipComponent } from "@feature/profile/component/profileChipComponent";
import { ProtocolChipComponent } from "@feature/protocol/component/protocolChipComponent";
import { SeparatorUi } from "@ui/SeparatorUi/SeparatorUi";
import { Serie } from "@common/model/Serie";
import { TablePhasesComponent } from "@feature/serie/component/tablePhasesComponent";
import {
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import { deviceStubService } from "@feature/device/service/deviceStubService";

import { ExerciseCode } from "@common/model/Exercise";
import { ProtocolCode } from "@common/model/Protocol";
import { RANGE_EXTENDED_READ_MM_DEFAULT } from "@common/service/constants";
import {
  getConfigurationValueFormattedLoadWeightInitial,
  getConfigurationValueFormattedLoadWeightUsed,
  getConfigurationValueFormattedPowerMaxCalculated,
  getConfigurationValueFormattedPowerMinCalculated,
  getConfigurationValueFormattedPowerReferenceUsed,
  getConfigurationValueFormattedRangeExtendedReadMaxMm,
  getConfigurationValueFormattedRangeExtendedReadMinMm,
  getConfigurationValueFormattedSpeedMax,
  getConfigurationValueFormattedSpeedMin,
  getConfigurationValueFormattedUsePower,
  getConfigurationValueFormattedUseSpeed,
  getRangeMinMaxExtended,
  getRangeUsedByConfigurations,
} from "@common/service/configurationService";
import {
  getRouteUuidPlaceholder,
  routes,
} from "@core/route";
import {
  router,
  useAppSelector,
} from "@core/redux/store";
import { selectSettingState } from "@feature/setting/slice/settingSlice";
import { useAlgorithmListQuery } from "@feature/algorithm/api/algorithmApi";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useAthleteGetQuery } from "@feature/athlete/api/athleteApi";
import { useChart } from "@feature/chart/hook/useChart";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import { useExerciseListQuery } from "@feature/exercise/api/exerciseApi";
import { useLocation } from "react-router";
import { useProfileGetQuery } from "@feature/profile/api/profileApi";
import { useProtocolListQuery } from "@feature/protocol/api/protocolApi";
import { useRelation } from "@feature/relation/hook/useRelation";
import { useRelationListQuery } from "@feature/relation/api/relationApi";
import { useRunHalfHeight } from "@feature/run/hook/useRunHalfHeight";
import { useStyles } from "@theme/makeStyles";

type Props = {
  serie: Serie;
  protocolUuid: string;
  exerciseUuid: string;
  profileUuid: string;
  workoutConfigurationValues: ConfigurationValue[];
  serieConfigurationValues: ConfigurationValue[];
  showChips?: boolean;
  isLive?: boolean;
  currentHistorySerieUuid?: string;
  historySeriesListLinked?: HistorySerie[];
};

export const SerieComponent = (props: Props) => {
  const exerciseListApi = useExerciseListQuery();
  const relationListApi = useRelationListQuery();
  const protocolListApi = useProtocolListQuery();
  const algorithmListApi = useAlgorithmListQuery();
  const configurationListApi = useConfigurationListQuery();
  const profileApi = useProfileGetQuery({ where: { uuid: props.profileUuid } });
  const athleteApi = useAthleteGetQuery({ where: { uuid: profileApi.data?.athleteUuid } }, { skip: !profileApi.isSuccess });

  return <>
    <ApiData endpoint={[
      exerciseListApi,
      relationListApi,
      protocolListApi,
      algorithmListApi,
      configurationListApi,
      profileApi,
      athleteApi,
    ]}>
      <SerieComponentChild
        {...props}
        exerciseList={exerciseListApi.data}
        relationList={relationListApi.data}
        protocolList={protocolListApi.data}
        algorithmList={algorithmListApi.data}
        configurationList={configurationListApi.data}
        athlete={athleteApi.data}
        profile={profileApi.data}
      />
    </ApiData>
  </>;
};

type PropsChild = Props & {
  exerciseList: Exercise[];
  relationList: Relation[];
  protocolList: Protocol[];
  algorithmList: Algorithm[];
  configurationList: Configuration[];
  athlete: Athlete;
  profile: Profile;
}

const SerieComponentChild = (props: PropsChild) => {
  const { theme } = useStyles();
  const settingState = useAppSelector(selectSettingState);
  const location = useLocation();
  const previousHistorySerieUuid = props.historySeriesListLinked?.[props.historySeriesListLinked.findIndex(historySerie => historySerie.uuid === props.currentHistorySerieUuid) - 1]?.uuid;
  const nextHistorySerieUuid = props.historySeriesListLinked?.[props.historySeriesListLinked.findIndex(historySerie => historySerie.uuid === props.currentHistorySerieUuid) + 1]?.uuid;
  const serieListCount = props.historySeriesListLinked?.length;
  const serieListCurrentPosition = props.historySeriesListLinked?.findIndex(historySerie => historySerie.uuid === props.currentHistorySerieUuid) + 1 ?? 0;

  const {
    endpoints: relationsEndpoints,
    getReleationByProtocolAndExercise,
  } = useRelation();

  const {
    endpoints: configurationEndpoints,
    getRelationConfigurationValuesByProtocolAndExercise,
  } = useConfiguration();

  const {
    endpoints: chartEndpoints,
    getRelationChartsByProtocolAndExercise,
    getRelationLiveChartsByProtocolAndExercise,
  } = useChart();

  const {
    isApiDataReady,
  } = useApiDataLogic([
    ...configurationEndpoints,
    ...relationsEndpoints,
    ...chartEndpoints,
  ]);

  const setAsDebugSerie = async() => {
    await deviceStubService.saveInStorage(props.serie.finalPoints);
    router.replace(routes.run.redirectPath);
  };

  const goToHistorySerieUuid = (historySerieUuid?: string) => {
    return () => {
      if (!historySerieUuid) {
        return;
      }

      if (location.pathname.startsWith(routes.historyView.basePath)) {
        router.replace(routes.historyView.routePath.replace(getRouteUuidPlaceholder("historySerie"), historySerieUuid));
      } else if (location.pathname.startsWith(routes.run.basePath)) {
        router.replace(routes.run.routePath.replace(getRouteUuidPlaceholder("historySerie", true), historySerieUuid));
      }
    };
  };

  const { runHalfHeight } = useRunHalfHeight();

  if (!isApiDataReady) {
    return null;
  }

  const exercise = props.exerciseList.find(exercise => exercise.uuid === props.exerciseUuid);
  const relation = getReleationByProtocolAndExercise(props.protocolUuid, props.exerciseUuid);
  const protocol = props.protocolList.find(protocol => protocol.uuid === props.protocolUuid);
  const protocolCode = protocol.code as ProtocolCode;
  const exerciseCode = exercise.code as ExerciseCode;
  const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(props.protocolUuid, props.exerciseUuid);

  const range = getRangeUsedByConfigurations(props.serieConfigurationValues);

  const rangeExtendedReadMinMm = getConfigurationValueFormattedRangeExtendedReadMinMm(relationConfigurationValues) ?? RANGE_EXTENDED_READ_MM_DEFAULT;
  const rangeExtendedReadMaxMm = getConfigurationValueFormattedRangeExtendedReadMaxMm(relationConfigurationValues) ?? RANGE_EXTENDED_READ_MM_DEFAULT;
  const rangeExtended = getRangeMinMaxExtended({
    range: range,
    minExtensionMm: rangeExtendedReadMinMm,
    maxExtensionMm: rangeExtendedReadMaxMm,
  });

  const powerReference = getConfigurationValueFormattedPowerReferenceUsed(props.serieConfigurationValues);
  const loadWeight = getConfigurationValueFormattedLoadWeightInitial(props.workoutConfigurationValues);
  const loadWeightUsed = getConfigurationValueFormattedLoadWeightUsed(props.serieConfigurationValues);
  const currentLoadWeight = props.isLive ? loadWeight : loadWeightUsed;

  const usePower = getConfigurationValueFormattedUsePower(relationConfigurationValues);
  const minPower = getConfigurationValueFormattedPowerMinCalculated(relationConfigurationValues, powerReference);
  const maxPower = getConfigurationValueFormattedPowerMaxCalculated(relationConfigurationValues, powerReference);

  const useSpeed = getConfigurationValueFormattedUseSpeed(relationConfigurationValues);
  const minSpeed = getConfigurationValueFormattedSpeedMin(relationConfigurationValues);
  const maxSpeed = getConfigurationValueFormattedSpeedMax(relationConfigurationValues);

  const charts = getRelationChartsByProtocolAndExercise(props.protocolUuid, props.exerciseUuid);
  const liveCharts = getRelationLiveChartsByProtocolAndExercise(props.protocolUuid, props.exerciseUuid);

  return <>
    <div>
      <ChartsComponent
        serie={props.serie}
        isLive={props.isLive}
        charts={charts}
        liveCharts={liveCharts}
        usePower={usePower}
        minPower={minPower}
        maxPower={maxPower}
        useSpeed={useSpeed}
        minSpeed={minSpeed}
        maxSpeed={maxSpeed}
        powerReference={powerReference}
        range={range}
        rangeExtended={rangeExtended}
        athleteUuid={props.athlete.uuid}
        exerciseUuid={exercise.uuid}
        serieUuid={props.currentHistorySerieUuid}
      />

      <SeparatorUi marginTop={10} />

      {
        props.showChips &&
		    <>
			    <ChipMultipleUi>
				    <AthleteChipComponent profileUuid={props.profileUuid} />
				    <ProfileChipComponent profileUuid={props.profileUuid} />
			    </ChipMultipleUi>
			    <ChipMultipleUi>
				    <ProtocolChipComponent protocolUuid={protocol.uuid} />
				    <ExerciseChipComponent exerciseUuid={exercise.uuid} />
			    </ChipMultipleUi>
		    </>
      }

      {
		    <DetailsPhasesComponent
			    serie={props.serie}
			    powerReference={powerReference}
			    loadWeightUsed={currentLoadWeight}
          listCurrentPosition={serieListCurrentPosition}
          listCount={serieListCount}
		    />
      }

      {
        range &&
				<TablePhasesComponent
				  serie={props.serie}
				  powerReference={powerReference}
				/>
      }

      {
        (
          previousHistorySerieUuid ||
          nextHistorySerieUuid
        ) &&
				<>
				  <IonIcon
				    icon={chevronBackOutline}
				    onClick={goToHistorySerieUuid(previousHistorySerieUuid)}
				    style={{
				      position: "fixed",
				      top: `calc(${ runHalfHeight + theme.runHeaderHeight + 35 }px)`,
				      left: 0,
				      zIndex: 100,
				      width: 30,
				      height: 30,
				      padding: 0,
				      color: previousHistorySerieUuid ? theme.colors.neon : theme.colors.greyTransparent,
				    }}
				  />

				  <IonIcon
				    icon={chevronForwardOutline}
				    onClick={goToHistorySerieUuid(nextHistorySerieUuid)}
				    style={{
				      position: "fixed",
				      top: `calc(${ runHalfHeight + theme.runHeaderHeight + 35 }px)`,
				      right: 0,
				      zIndex: 100,
				      width: 30,
				      height: 30,
				      padding: 0,
				      color: nextHistorySerieUuid ? theme.colors.neon : theme.colors.greyTransparent,
				    }}
				  />
				</>
      }

      {
        settingState.isDebug &&
				<>
				  <SeparatorUi />
				  <PrimaryButtonUi
				    onClick={setAsDebugSerie}
				    label={"Debug this serie"}
				  />
				</>
      }
    </div>
  </>;
};
