import { t } from "@lingui/macro";

export const getProtocolNameByCode = (code: string): string => {
  switch (code) {
    case "SENSOR_TEST":
      return t`SENSOR_TEST_TITLE`;
    case "FMAX":
      return t`FMAX_TITLE`;
    case "FMCP":
      return t`FMCP_TITLE`;
    case "FE":
      return t`FE_TITLE`;
    case "RFV":
      return t`RFV_TITLE`;
    case "IM":
      return t`IM_TITLE`;
    case "RM":
      return t`RM_TITLE`;
    case "SL":
      return t`SL_TITLE`;
    case "IF":
      return t`IF_TITLE`;
    case "SD_SP":
      return t`SD_SP_TITLE`;
    case "JUMP":
      return t`JUMP_TITLE`;
    case "ANALYSIS":
      return t`ANALYSIS_TITLE`;
  }
  return code;
};
