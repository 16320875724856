
import {
  RUN_STATUS_RUNNING,
  selectRunState,
} from "@feature/run/slice/runSlice";
import { Serie } from "@common/model/Serie";
import { calculateIsInStall } from "@common/service/pointService";
import { useAppSelector } from "@core/redux/store";
import { useRunStop } from "@feature/run/hook/useRunStop";

type Props = {
  serie: Serie;
  seconds: number;
}

export const useRunAutoStop = () => {
  const runState = useAppSelector(selectRunState);

  const { runStop } = useRunStop();

  const runAutoStop = (props: Props) => {
    if (runState.runStatus !== RUN_STATUS_RUNNING) {
      return;
    }

    const limitMs = props.seconds * 1000;
    const isInStall = calculateIsInStall(
      props.serie.finalPoints,
      70,
      limitMs
    );
    if (isInStall) {
      runStop(props.seconds);
    }
  };

  return {
    runAutoStop: runAutoStop,
  };
};
