import { Locale } from "@lingui/core";
import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";
import {
  STORE_SETTING_KEY,
} from "@feature/setting/settingConstants";

type PhysicUnitPowerValues = "W" | "kW";
type PhysicUnitMillimeterValues = "mm" | "cm" | "m";
type PhysicUnitMillisecondValues = "ms" | "s";
type PhysicUnitSpeedValues = "m/s" | "cm/s";

type PhysicsUnits = {
  power: PhysicUnitPowerValues;
  displacement: PhysicUnitMillimeterValues;
  duration: PhysicUnitMillisecondValues;
  averageSpeed: PhysicUnitSpeedValues;
}

type InitialStateModelInterface = {
  isDebug: boolean;
  isBeepEnabled: boolean;
  isCounterEnabled: boolean;
  showPreDirection: boolean;
  showPostDirection: boolean;
  isShowChartDebug: boolean;
  isShowMovementDetails: boolean;
  language: Locale;
  physicsUnits: PhysicsUnits;
}

const initialState: InitialStateModelInterface = {
  isDebug: false,
  isBeepEnabled: true,
  isCounterEnabled: false,
  showPreDirection: false,
  showPostDirection: false,
  isShowChartDebug: false,
  isShowMovementDetails: true,
  language: "",
  physicsUnits: {
    power: "W",
    displacement: "mm",
    duration: "ms",
    averageSpeed: "m/s",
  },
};

export const settingSlice = createSlice({
  name: STORE_SETTING_KEY,
  initialState: initialState,
  reducers: {
    setBeepEnabled: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isBeepEnabled = action.payload;
    },
    setCounterEnabled: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCounterEnabled = action.payload;
    },
    setShowPreDirection: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showPreDirection = action.payload;
    },
    setShowPostDirection: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showPostDirection = action.payload;
    },
    setIsDebug: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDebug = action.payload;
      if (action.payload === false) {
        state.isShowChartDebug = false;
      }
    },
    setIsShowMovementDetails: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isShowMovementDetails = action.payload;
    },
    setIsShowChartDebug: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isShowChartDebug = action.payload;
    },
    setLanguage: (
      state,
      action: PayloadAction<Locale>
    ) => {
      state.language = action.payload;
    },
    switchPhysicsUnitPower: state => {
      switch (state.physicsUnits.power) {
        case "W":
          state.physicsUnits.power = "kW";
          return;
        case "kW":
          state.physicsUnits.power = "W";
          return;
      }
    },
    switchPhysicsUnitDisplacement: state => {
      switch (state.physicsUnits.displacement) {
        case "cm":
          state.physicsUnits.displacement = "m";
          return;
        case "m":
          state.physicsUnits.displacement = "mm";
          return;
        case "mm":
          state.physicsUnits.displacement = "cm";
          return;
      }
    },
    switchPhysicsUnitDuration: state => {
      switch (state.physicsUnits.duration) {
        case "ms":
          state.physicsUnits.duration = "s";
          return;
        case "s":
          state.physicsUnits.duration = "ms";
          return;
      }
    },
    switchPhysicsUnitAverageSpeed: state => {
      switch (state.physicsUnits.averageSpeed) {
        case "m/s":
          state.physicsUnits.averageSpeed = "cm/s";
          return;
        case "cm/s":
          state.physicsUnits.averageSpeed = "m/s";
          return;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});

export const {
  setBeepEnabled,
  setCounterEnabled,
  setShowPreDirection,
  setShowPostDirection,
  setIsDebug,
  setIsShowChartDebug,
  setIsShowMovementDetails,
  setLanguage,
  switchPhysicsUnitPower,
  switchPhysicsUnitDisplacement,
  switchPhysicsUnitDuration,
  switchPhysicsUnitAverageSpeed,
} = settingSlice.actions;

export const selectSettingState = (state: RootState) => state[STORE_SETTING_KEY];
