import { API_CACHE_TAG_POWER } from "@feature/power/powerConstants";
import {
  Power,
  PowerCreateInput,
} from "@common/type-graphql/generated";
import { PowerListInputType } from "@common/type-graphql/power/input-type/power-list.input-type";
import { PowerResetInputType } from "@common/type-graphql/power/input-type/power-reset.input-type";
import {
  api,
} from "@core/api";
import {
  powerCreateQuery,
  powerCreateTransformResponse,
} from "@common/type-graphql/power/gql/power-create.g";
import {
  powerListQuery,
  powerListTransformResponse,
} from "@common/type-graphql/power/gql/power-list.g";
import {
  powerResetQuery,
  powerResetTransformResponse,
} from "@common/type-graphql/power/gql/power-reset.g";

export const powerApi = api.injectEndpoints({
  endpoints: builder => ({
    powerList: builder.query<Power[], { where: PowerListInputType }>({
      query: variables => ({
        document: powerListQuery,
        variables: variables,
      }),
      transformResponse: powerListTransformResponse,
      providesTags: (result, error, { where }) => {
        const tagId = `${ where.athleteUuid }|${ where.exerciseUuid }`;
        return [
          {
            type: API_CACHE_TAG_POWER,
            id: tagId,
          },
        ];
      },
    }),
    powerCreate: builder.mutation<Power, { data: PowerCreateInput }>({
      query: variables => ({
        document: powerCreateQuery,
        variables: variables,
      }),
      transformResponse: powerCreateTransformResponse,
      invalidatesTags: (result, error, { data }) => {
        const tagId = `${ data.Athlete.connect.uuid }|${ data.Exercise.connect.uuid }`;
        return [
          {
            type: API_CACHE_TAG_POWER,
            id: tagId,
          },
        ];
      },
    }),
    powerReset: builder.mutation<Power[], { data: PowerResetInputType }>({
      query: variables => ({
        document: powerResetQuery,
        variables: variables,
      }),
      transformResponse: powerResetTransformResponse,
      invalidatesTags: (result, error, { data }) => {
        const tagId = `${ data.athleteUuid }|${ data.exerciseUuid }`;
        return [
          {
            type: API_CACHE_TAG_POWER,
            id: tagId,
          },
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  usePowerListQuery,
  usePowerCreateMutation,
  usePowerResetMutation,
} = powerApi;
