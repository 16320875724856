import { runCalibrationStop } from "@feature/run/slice/runCalibrationSlice";
import { runStopThunk } from "@feature/run/thunk/runThunk";
import { useAppDispatch } from "@core/redux/store";

export const useRunStop = () => {
  const dispatch = useAppDispatch();

  const stop = (recoverAmount: number) => {
    dispatch(runStopThunk({
      recoverAmount: recoverAmount,
    }));
    dispatch(runCalibrationStop());
  };

  return { runStop: stop };
};
