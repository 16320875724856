import {
  CONFIGURATION_CODE_LOAD_WEIGHT_USED,
  CONFIGURATION_CODE_POWER_REFERENCE_USED,
  CONFIGURATION_CODE_RANGE_MAX_USED,
  CONFIGURATION_CODE_RANGE_MIN_USED,
} from "@common/model/Configuration";
import {
  Calibration,
  HistorySerie,
  HistoryWorkout,
  Training,
} from "@common/type-graphql/generated";
import { ConfigurationValue } from "@common/model/ConfigurationValue";
import {
  formatConfigurationValues,
  getConfigurationValueFormattedAutoStopTime,
  getConfigurationValueFormattedConsecutiveBadRepetitionsCount,
  getConfigurationValueFormattedFeedbackGoTollerancePercent,
  getConfigurationValueFormattedLoadWeightInitial,
  getConfigurationValueFormattedLoadWeightUsed,
  getConfigurationValueFormattedPowerMax,
  getConfigurationValueFormattedPowerMin,
  getConfigurationValueFormattedPowerReferenceAutoUpdate,
  getConfigurationValueFormattedRangeMaxReductionMm,
  getConfigurationValueFormattedRangeMaxReductionPercent,
  getConfigurationValueFormattedRangeMinReductionMm,
  getConfigurationValueFormattedRangeMinReductionPercent,
  getConfigurationValueFormattedRecoveryTimeInitial,
  getConfigurationValueFormattedRecoveryTimeMax,
  getConfigurationValueFormattedRecoveryTimeMin,
  getConfigurationValueFormattedRecoveryTimeUsed,
  getConfigurationValueFormattedShutupSeconds,
  getConfigurationValueFormattedSpeedMax,
  getConfigurationValueFormattedSpeedMin,
  getConfigurationValueFormattedUsePower,
  getConfigurationValueFormattedUseSpeed,
} from "@common/service/configurationService";
import { selectRunState } from "@feature/run/slice/runSlice";
import { useApiDataLogic } from "@feature/api/hook/useApiDataLogic";
import { useAppSelector } from "@core/redux/store";
import { useCalibrationListQuery } from "@feature/calibration/api/calibrationApi";
import {
  useCallback,
  useMemo,
} from "react";
import { useConfigurationListQuery } from "@feature/configuration/api/configurationApi";
import { usePowerDataGetter } from "@feature/power/hook/usePowerDataGetter";
import { useRelation } from "@feature/relation/hook/useRelation";
import { useRunRange } from "@feature/run/hook/useRunRange";
import { useRunTraining } from "@feature/run/hook/useRunTraining";

export const useConfiguration = () => {
  const runState = useAppSelector(selectRunState);

  const configurationListApi = useConfigurationListQuery();
  const calibrationListApi = useCalibrationListQuery();

  const configurationList = useMemo(
    () => configurationListApi.data ?? [],
    [ configurationListApi.data ]
  );

  const {
    endpoints : relationEndpoints,
    getReleationByProtocolAndExercise,
  } = useRelation();

  const {
    endpoints: runRangeEndpoints,
    runRangeMinMax,
  } = useRunRange();

  const {
    runTraining,
    endpoints: trainingEndpoints,
  } = useRunTraining();

  const {
    endpoints,
    isApiDataReady,
  } = useApiDataLogic([
    configurationListApi,
    calibrationListApi,
    ...relationEndpoints,
    ...runRangeEndpoints,
    ...trainingEndpoints,
  ]);

  const getRelationConfigurationValuesByProtocolAndExercise = (
    protocolUuid: string,
    exerciseUuid: string
  ): ConfigurationValue[] => {
    if (!isApiDataReady) {
      return [];
    }

    const relation = getReleationByProtocolAndExercise(protocolUuid, exerciseUuid);
    if (!relation) {
      return [];
    }

    return formatConfigurationValues(configurationList, relation.RelationConfiguration);
  };

  const getTrainingConfigurationValues = useCallback((
    training: Training
  ): ConfigurationValue[] => {
    if (!isApiDataReady || !training) {
      return [];
    }

    return formatConfigurationValues(configurationList, training.TrainingConfiguration);
  }, [
    configurationList,
    isApiDataReady,
  ]);

  const getRunConfigurationValues = (): ConfigurationValue[] => {
    if (!isApiDataReady) {
      return [];
    }

    const runConfigurations: ConfigurationValue[] = [];

    for (const configuration of configurationList) {
      if (!configuration.saveInHistorySerie) {
        continue;
      }
      const runPowerReference = getRunConfigurationPowerReferenceCurrent();

      switch (configuration.code) {
        case CONFIGURATION_CODE_RANGE_MIN_USED:
          if (!runRangeMinMax) {
            break;
          }
          runConfigurations.push({
            code: configuration.code,
            value: runRangeMinMax.min,
          });
          break;
        case CONFIGURATION_CODE_RANGE_MAX_USED:
          if (!runRangeMinMax) {
            break;
          }
          runConfigurations.push({
            code: configuration.code,
            value: runRangeMinMax.max,
          });
          break;
        case CONFIGURATION_CODE_POWER_REFERENCE_USED:
          if (!runPowerReference) {
            break;
          }
          runConfigurations.push({
            code: configuration.code,
            value: runPowerReference,
          });
          break;
        case CONFIGURATION_CODE_LOAD_WEIGHT_USED:
          if (!runConfigurationLoadWeightCurrent) {
            break;
          }
          runConfigurations.push({
            code: configuration.code,
            value: runConfigurationLoadWeightCurrent,
          });
          break;
      }
    }

    return runConfigurations;
  };

  const getHistoryWorkoutConfigurationValues = (historyWorkout: HistoryWorkout): ConfigurationValue[] => {
    if (!isApiDataReady) {
      return [];
    }

    return formatConfigurationValues(configurationList, historyWorkout.HistoryWorkoutConfiguration);
  };

  const getHistorySerieConfigurationValues = (historySerie: HistorySerie): ConfigurationValue[] => {
    if (!isApiDataReady) {
      return [];
    }

    return formatConfigurationValues(configurationList, historySerie.HistorySerieConfiguration);
  };

  const runConfigurationLoadWeightInitial = useMemo<number>(() => {
    if (!isApiDataReady) {
      return null;
    }

    const trainingConfigurationValues = getTrainingConfigurationValues(runTraining);

    return getConfigurationValueFormattedLoadWeightInitial(trainingConfigurationValues);
  }, [
    getTrainingConfigurationValues,
    isApiDataReady,
    runTraining,
  ]);

  const getRunConfigurationLoadWeightUsed = () => {
    if (!isApiDataReady) {
      return null;
    }

    const runConfigurationValues = getRunConfigurationValues();

    return getConfigurationValueFormattedLoadWeightUsed(runConfigurationValues);
  };

  const getRunConfigurationAutoStopTime = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedAutoStopTime(relationConfigurationValues);
  };

  const getRunConfigurationRecoveryTimeMin = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedRecoveryTimeMin(relationConfigurationValues);
  };

  const getRunConfigurationRecoveryTimeMax = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedRecoveryTimeMax(relationConfigurationValues);
  };

  const getRunConfigurationRecoveryTimeInitial = () => {
    if (!isApiDataReady) {
      return null;
    }

    const trainingConfigurationValues = getTrainingConfigurationValues(runTraining);

    return getConfigurationValueFormattedRecoveryTimeInitial(trainingConfigurationValues);
  };

  const getRunConfigurationRecoveryTimeUsed = () => {
    if (!isApiDataReady) {
      return null;
    }

    const trainingConfigurationValues = getTrainingConfigurationValues(runTraining);

    return getConfigurationValueFormattedRecoveryTimeUsed(trainingConfigurationValues);
  };

  const getRunConfigurationUsePower = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedUsePower(relationConfigurationValues);
  };

  const getRunConfigurationPowerMin = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedPowerMin(relationConfigurationValues);
  };

  const getRunConfigurationPowerMax = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedPowerMax(relationConfigurationValues);
  };

  const getRunConfigurationUseSpeed = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedUseSpeed(relationConfigurationValues);
  };

  const getRunConfigurationSpeedMin = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedSpeedMin(relationConfigurationValues);
  };

  const getRunConfigurationSpeedMax = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedSpeedMax(relationConfigurationValues);
  };

  const getRunConfigurationConsecutiveBadRepetitionsCount = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedConsecutiveBadRepetitionsCount(relationConfigurationValues);
  };

  const getRunConfigurationShutupSeconds = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedShutupSeconds(relationConfigurationValues);
  };

  const getRunConfigurationFeedbackGoTollerancePercent = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedFeedbackGoTollerancePercent(relationConfigurationValues);
  };

  const getRunConfigurationPowerReferenceAutoUpdate = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedPowerReferenceAutoUpdate(relationConfigurationValues);
  };

  const runConfigurationLoadWeightCurrent = useMemo<number>(() => {
    if (!isApiDataReady) {
      return null;
    }

    const existing = runState.loadWeights.find(loadWeight => loadWeight.trainingUuid === runTraining.uuid);
    if (existing) {
      return existing.value;
    }

    return runConfigurationLoadWeightInitial;
  }, [
    isApiDataReady,
    runConfigurationLoadWeightInitial,
    runState,
    runTraining,
  ]);

  const { data: powerData } = usePowerDataGetter({
    athleteUuid: runTraining?.athleteUuid,
    exerciseUuid: runTraining?.exerciseUuid,
    loadWeight: runConfigurationLoadWeightCurrent,
  }, {
    skip: !runTraining || !runConfigurationLoadWeightCurrent,
  });

  const getRunConfigurationPowerReferenceCurrent = () => {
    if (!isApiDataReady) {
      return null;
    }
    return powerData.powerReference;
  };

  const getRunConfigurationRangeMinReductionMm = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedRangeMinReductionMm(relationConfigurationValues);
  };

  const getRunConfigurationRangeMaxReductionMm = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedRangeMaxReductionMm(relationConfigurationValues);
  };

  const getRunConfigurationRangeMinReductionPercent = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedRangeMinReductionPercent(relationConfigurationValues);
  };

  const getRunConfigurationRangeMaxReductionPercent = (
    protocolUuid?: string,
    exerciseUuid?: string
  ) => {
    if (!isApiDataReady) {
      return null;
    }

    const relationConfigurationValues = getRelationConfigurationValuesByProtocolAndExercise(
      protocolUuid ?? runTraining.protocolUuid,
      exerciseUuid ?? runTraining.exerciseUuid
    );

    return getConfigurationValueFormattedRangeMaxReductionPercent(relationConfigurationValues);
  };

  const getRelationCalibrationsByProtocolAndExercise = (
    protocolUuid: string,
    exerciseUuid: string
  ): Calibration[] => {
    if (!isApiDataReady) {
      return [];
    }

    const relation = getReleationByProtocolAndExercise(protocolUuid, exerciseUuid);
    if (!relation) {
      return [];
    }

    return relation.RelationCalibration.map(
      relationCalibration => calibrationListApi.data.find(
        c => c.uuid === relationCalibration.calibrationUuid
      )
    );
  };

  return {
    endpoints: endpoints,
    getRelationConfigurationValuesByProtocolAndExercise: getRelationConfigurationValuesByProtocolAndExercise,
    getTrainingConfigurationValues: getTrainingConfigurationValues,
    getHistoryWorkoutConfigurationValues: getHistoryWorkoutConfigurationValues,
    getHistorySerieConfigurationValues: getHistorySerieConfigurationValues,
    getRunConfigurationValues: getRunConfigurationValues,
    getRunConfigurationLoadWeightUsed: getRunConfigurationLoadWeightUsed,
    getRunConfigurationAutoStopTime: getRunConfigurationAutoStopTime,
    getRunConfigurationRecoveryTimeMin: getRunConfigurationRecoveryTimeMin,
    getRunConfigurationRecoveryTimeMax: getRunConfigurationRecoveryTimeMax,
    getRunConfigurationRecoveryTimeInitial: getRunConfigurationRecoveryTimeInitial,
    getRunConfigurationRecoveryTimeUsed: getRunConfigurationRecoveryTimeUsed,
    getRunConfigurationUsePower: getRunConfigurationUsePower,
    getRunConfigurationPowerMin: getRunConfigurationPowerMin,
    getRunConfigurationPowerMax: getRunConfigurationPowerMax,
    getRunConfigurationUseSpeed: getRunConfigurationUseSpeed,
    getRunConfigurationSpeedMin: getRunConfigurationSpeedMin,
    getRunConfigurationSpeedMax: getRunConfigurationSpeedMax,
    getRunConfigurationConsecutiveBadRepetitionsCount: getRunConfigurationConsecutiveBadRepetitionsCount,
    getRunConfigurationShutupSeconds: getRunConfigurationShutupSeconds,
    getRunConfigurationFeedbackGoTollerancePercent: getRunConfigurationFeedbackGoTollerancePercent,
    getRunConfigurationPowerReferenceAutoUpdate: getRunConfigurationPowerReferenceAutoUpdate,
    runConfigurationLoadWeightCurrent: runConfigurationLoadWeightCurrent,
    getRunConfigurationPowerReferenceCurrent: getRunConfigurationPowerReferenceCurrent,
    getRunConfigurationRangeMinReductionMm: getRunConfigurationRangeMinReductionMm,
    getRunConfigurationRangeMaxReductionMm: getRunConfigurationRangeMaxReductionMm,
    getRunConfigurationRangeMinReductionPercent: getRunConfigurationRangeMinReductionPercent,
    getRunConfigurationRangeMaxReductionPercent: getRunConfigurationRangeMaxReductionPercent,
    getRelationCalibrationsByProtocolAndExercise: getRelationCalibrationsByProtocolAndExercise,
  };
};
