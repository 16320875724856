import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  PowerData,
  PowerMetaData,
  STORE_POWER_KEY,
} from "@feature/power/powerConstants";
import { RESET_APP_STATE } from "@core/api";
import { RootState } from "@core/redux/store";

type StateModelInterface = {
  powerDataList: PowerData[];
};

const initialState: StateModelInterface = {
  powerDataList: [],
};

export const powerSlice = createSlice({
  name: STORE_POWER_KEY,
  initialState: initialState,
  reducers: {
    powerSetSlice: (state, action: PayloadAction<PowerData>) => {
      const powerData = action.payload;

      // Check if the element already exists
      const index = state.powerDataList.findIndex(
        item =>
          item.athleteUuid === powerData.athleteUuid &&
          item.exerciseUuid === powerData.exerciseUuid &&
          item.loadWeight === powerData.loadWeight
      );

      // If it exists, update it else, add it
      if (index !== -1) {
        state.powerDataList[index] = powerData;
      } else {
        state.powerDataList.push(powerData);
      }
    },
    powerResetSlice: (state, action: PayloadAction<PowerMetaData>) => {
      const powerData: PowerData = {
        ...action.payload,
        powerReference: null,
      };

      // Check if the element already exists
      const index = state.powerDataList.findIndex(
        item =>
          item.athleteUuid === powerData.athleteUuid &&
          item.exerciseUuid === powerData.exerciseUuid &&
          item.loadWeight === powerData.loadWeight
      );

      if (index !== -1) {
        state.powerDataList[index] = powerData;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(RESET_APP_STATE, () => initialState);
  },
});

export const {
  powerSetSlice,
  powerResetSlice,
} = powerSlice.actions;

export const selectPowerState = (state: RootState) => state[STORE_POWER_KEY];
