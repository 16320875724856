import {
  API_CACHE_TAG_HISTORY_SERIE,
  API_CACHE_TAG_HISTORY_TRAINING_SESSION,
  API_CACHE_TAG_HISTORY_WORKOUT,
  UUID_NAME_HISTORY_SERIE,
  UUID_NAME_HISTORY_SERIE_CONFIGURATION,
  UUID_NAME_HISTORY_TRAINING_SESSION,
  UUID_NAME_HISTORY_WORKOUT,
  UUID_NAME_HISTORY_WORKOUT_CONFIGURATION,
} from "@feature/history/historyConstants";
import {
  API_CACHE_TAG_LIST_VALUE,
  api,
} from "@core/api";
import {
  CONFIGURATION_CODE_LOAD_WEIGHT_USED,
  CONFIGURATION_CODE_POWER_REFERENCE_USED,
  CONFIGURATION_CODE_RANGE_EXISTING,
  CONFIGURATION_CODE_RANGE_MAX_USED,
  CONFIGURATION_CODE_RANGE_MIN_USED,
} from "@common/model/Configuration";
import {
  Configuration,
  HistorySerieConfigurationCreateManyHistorySerieInput,
  HistoryWorkoutConfigurationCreateManyHistoryWorkoutInput,
  Range,
  Sheet,
  Training,
} from "@common/type-graphql/generated";
import {
  DIRECTION_END,
  DIRECTION_STABLE,
} from "@common/model/Direction";
import {
  HistoryStateModel,
  setHistoryLastWorkoutData,
  setHistorySerieUuid,
  setHistoryTrainingSessionUuid,
  setHistoryWorkoutUuid,
} from "@feature/history/slice/historySlice";
import { LoadWeight } from "@common/model/LoadWeight";

import { RangeMinMax } from "@common/model/Range";
import { calculateMaxPower } from "@common/service/serieService";
import { confirm } from "@feature/confirm/service/confirm";
import { currentSerie } from "@feature/run/data/currentSerie";
import { generateUuid } from "@util/generateUuid";
import {
  getConfigurationByUuid,
  getConfigurationUuidByCode,
} from "@common/service/configurationService";
import { selectRunCalibrationState } from "@feature/run/slice/runCalibrationSlice";
import { t } from "@lingui/macro";
import {
  useAppDispatch,
  useAppSelector,
} from "@core/redux/store";
import { useConfiguration } from "@feature/configuration/hook/useConfiguration";
import { useHistorySerieCreateMutation } from "@feature/history/api/historyApi";
import { useIonActionSheet } from "@ionic/react";
import { usePowerDataSetter } from "@feature/power/hook/usePowerDataSetter";
import { useRunRange } from "@feature/run/hook/useRunRange";

type SaveProps = {
  historyState: HistoryStateModel;
  configurationList: Configuration[];
  rangeList: Range[];
  training: Training;
  sheet: Sheet;
  loadWeights: LoadWeight[];
}

export const useRunSave = () => {
  const dispatch = useAppDispatch();
  const runCalibrationState = useAppSelector(selectRunCalibrationState);
  const { setPowerData } = usePowerDataSetter();

  const [ historySerieCreate ] = useHistorySerieCreateMutation();
  const [ present ] = useIonActionSheet();
  const { runRangeMinMax } = useRunRange();

  const {
    getRunConfigurationLoadWeightUsed,
    getRunConfigurationUsePower,
    runConfigurationLoadWeightCurrent,
    getRunConfigurationPowerReferenceCurrent,
  } = useConfiguration();

  const runPowerReference = getRunConfigurationPowerReferenceCurrent();

  const save = async(props: SaveProps) => {
    // Do not save if is calibrating
    if (runCalibrationState.isCalibrating) {
      return;
    }

    // Do not save if there are no final phases
    if (!currentSerie.finalPhases) {
      return;
    }
    const goodPhases = currentSerie.finalPhases.filter(phase =>
      phase.direction !== DIRECTION_STABLE &&
      phase.direction !== DIRECTION_END
    );
    if (
      runRangeMinMax &&
      !goodPhases.length
    ) {
      return;
    }

    // Disable calculation of rang in run because of new calibration system
    // if (!runRangeMinMax) {
    //   ignoreShorterThanConcentricPhases(currentSerie.mergedPhases, PHASE_MIN_DISPLACEMENT_WITHOUT_RANGE, PHASE_IGNORE_REASON_TINY);
    //   const newRange = calculateRange(currentSerie.mergedPhases);
    //   if (newRange) {
    //     try {
    //       rangeCreate({
    //         data: {
    //           data: JSON.stringify(newRange),
    //           Athlete: { connect: { uuid: props.training.athleteUuid } },
    //           Exercise: { connect: { uuid: props.training.exerciseUuid } },
    //         },
    //       });
    //     } catch (e) {
    //       console.error(e);
    //     }
    //   } else {
    //     notify({
    //       header: t`Invalid range detected`,
    //       subHeader: t`Please do another set to detect what your range is`,
    //       present: present,
    //     });
    //   }
    // }

    // History
    const historyWorkoutConfigurations = getHistoryWorkoutConfiguration(props);
    const historySerieConfigurations = getHistorySerieConfiguration(props, runRangeMinMax);

    // History TrainingSession
    let historyTrainingSessionUuid = props.historyState.historyTrainingSessionUuid;

    if (!historyTrainingSessionUuid) {
      historyTrainingSessionUuid = generateUuid(UUID_NAME_HISTORY_TRAINING_SESSION, true);
      dispatch(setHistoryTrainingSessionUuid(historyTrainingSessionUuid));
    }

    // History Workout
    const currentProfileUuid = props.training.profileUuid;
    const currentExerciseUuid = props.training.exerciseUuid;
    const currentProtocolUuid = props.training.protocolUuid;
    let newHistoryWorkoutUuid = props.historyState.historyWorkoutUuid;
    let needToCreateaNewWorkout = false;
    if (!props.historyState.historyLastWorkoutData) {
      needToCreateaNewWorkout = true;
    } else if (
      props.historyState.historyLastWorkoutData.historyTrainingSessionUuid !== historyTrainingSessionUuid ||
      props.historyState.historyLastWorkoutData.profileUuid !== currentProfileUuid ||
      props.historyState.historyLastWorkoutData.exerciseUuid !== currentExerciseUuid ||
      props.historyState.historyLastWorkoutData.protocolUuid !== currentProtocolUuid
    ) {
      needToCreateaNewWorkout = true;
    }
    if (needToCreateaNewWorkout) {
      newHistoryWorkoutUuid = generateUuid(UUID_NAME_HISTORY_WORKOUT, true);
      dispatch(setHistoryWorkoutUuid(newHistoryWorkoutUuid));
      dispatch(setHistoryLastWorkoutData({
        historyTrainingSessionUuid: historyTrainingSessionUuid,
        profileUuid: currentProfileUuid,
        exerciseUuid: currentExerciseUuid,
        protocolUuid: currentProtocolUuid,
      }));
    }
    const historyWorkoutUuid = newHistoryWorkoutUuid;

    // History Serie
    const newHistorySerieUuid = generateUuid(UUID_NAME_HISTORY_SERIE, true);
    dispatch(setHistorySerieUuid(newHistorySerieUuid));
    const historySerieUuid = newHistorySerieUuid;

    const date = new Date();

    await historySerieCreate({
      data: {
        uuid: historySerieUuid,
        data: JSON.stringify(currentSerie.originalPoints),
        dateTime: date,
        HistorySerieConfiguration: { createMany: { data: historySerieConfigurations } },
        HistoryWorkout: {
          connectOrCreate: {
            where: { uuid: historyWorkoutUuid },
            create: {
              uuid: historyWorkoutUuid,
              dateTime: date,
              Profile: { connect: { uuid: props.training.profileUuid } },
              Protocol: { connect: { uuid: props.training.protocolUuid } },
              Exercise: { connect: { uuid: props.training.exerciseUuid } },
              HistoryTrainingSession: {
                connectOrCreate: {
                  where: { uuid: historyTrainingSessionUuid },
                  create: {
                    uuid: historyTrainingSessionUuid,
                    sheetUuid: props.sheet.uuid,
                    sheetName: props.sheet.name,
                    sheetColor: props.sheet.color,
                    sheetIsQuick: props.sheet.isQuick,
                    dateTime: date,
                  },
                },
              },
              HistoryWorkoutConfiguration: { createMany: { data: historyWorkoutConfigurations } },
            },
          },
        },
      },
    }).unwrap();

    const usePower = getRunConfigurationUsePower();
    if (
      usePower &&
      runRangeMinMax
    ) {
      // Set a new currentMaxPower
      const currentMaxPower = calculateMaxPower(currentSerie);
      if (!runPowerReference && currentMaxPower) {
        setPowerData({
          powerData: {
            athleteUuid: props.training.athleteUuid,
            exerciseUuid: props.training.exerciseUuid,
            loadWeight: runConfigurationLoadWeightCurrent,
            powerReference: currentMaxPower,
          },
          historySerieUuid: historySerieUuid,
        });
      } else if (currentMaxPower > runPowerReference) {
        // Ask if need to update
        confirm({
          header: t`A higher maximum power reference has been detected.`,
          subHeader: t`Do you want to update the maximum power reference?`,
          yesText: t`Use the new power ${ currentMaxPower } W`,
          noText: t`Still use ${ runPowerReference } W`,
          present: present,
          action: async() => {
            setPowerData({
              powerData: {
                athleteUuid: props.training.athleteUuid,
                exerciseUuid: props.training.exerciseUuid,
                loadWeight: runConfigurationLoadWeightCurrent,
                powerReference: currentMaxPower,
              },
              historySerieUuid: historySerieUuid,
            });
          },
        });
      }
    }

    dispatch(api.util.invalidateTags([
      {
        type: API_CACHE_TAG_HISTORY_TRAINING_SESSION,
        id: API_CACHE_TAG_LIST_VALUE,
      },
      {
        type: API_CACHE_TAG_HISTORY_WORKOUT,
        id: API_CACHE_TAG_LIST_VALUE,
      },
      {
        type: API_CACHE_TAG_HISTORY_SERIE,
        id: API_CACHE_TAG_LIST_VALUE,
      },
    ]));
  };

  const getHistoryWorkoutConfiguration = (props: SaveProps): HistoryWorkoutConfigurationCreateManyHistoryWorkoutInput[] => {
    const trainingConfigurations = props.training.TrainingConfiguration;
    const historyWorkoutConfigurations: HistoryWorkoutConfigurationCreateManyHistoryWorkoutInput[] = [];

    for (const trainingConfiguration of trainingConfigurations) {
      historyWorkoutConfigurations.push({
        configurationUuid: trainingConfiguration.configurationUuid,
        value: trainingConfiguration.value,
        uuid: generateUuid(UUID_NAME_HISTORY_WORKOUT_CONFIGURATION, true),
      });
    }

    return historyWorkoutConfigurations;
  };

  const getHistorySerieConfiguration = (
    props: SaveProps,
    rangeMinMax: RangeMinMax | null
  ): HistorySerieConfigurationCreateManyHistorySerieInput[] => {
    const trainingConfigurations = props.training.TrainingConfiguration;

    const historySerieConfigurations: HistorySerieConfigurationCreateManyHistorySerieInput[] = [];

    for (const trainingConfiguration of trainingConfigurations) {
      const configuration = getConfigurationByUuid(props.configurationList, trainingConfiguration.configurationUuid);
      if (configuration.saveInHistorySerie === false) {
        continue;
      }
      historySerieConfigurations.push({
        configurationUuid: trainingConfiguration.configurationUuid,
        value: trainingConfiguration.value,
        uuid: generateUuid(UUID_NAME_HISTORY_SERIE_CONFIGURATION, true),
      });
    }

    const rangeExistingUuid = getConfigurationUuidByCode(props.configurationList, CONFIGURATION_CODE_RANGE_EXISTING);
    if (rangeMinMax) {
      const rangeMinConfigurationUuid = getConfigurationUuidByCode(props.configurationList, CONFIGURATION_CODE_RANGE_MIN_USED);
      const rangeMaxConfigurationUuid = getConfigurationUuidByCode(props.configurationList, CONFIGURATION_CODE_RANGE_MAX_USED);
      historySerieConfigurations.push({
        configurationUuid: rangeMinConfigurationUuid,
        value: (rangeMinMax.min).toString(),
      });
      historySerieConfigurations.push({
        configurationUuid: rangeMaxConfigurationUuid,
        value: (rangeMinMax.max).toString(),
      });
      historySerieConfigurations.push({
        configurationUuid: rangeExistingUuid,
        value: "true",
      });
    } else {
      historySerieConfigurations.push({
        configurationUuid: rangeExistingUuid,
        value: "false",
      });
    }

    if (runPowerReference) {
      const powerReferenceConfigurationUuid = getConfigurationUuidByCode(props.configurationList, CONFIGURATION_CODE_POWER_REFERENCE_USED);
      historySerieConfigurations.push({
        configurationUuid: powerReferenceConfigurationUuid,
        value: (runPowerReference).toString(),
      });
    }

    const loadWeightUsed = getRunConfigurationLoadWeightUsed();
    const loadWeightConfigurationUuid = getConfigurationUuidByCode(props.configurationList, CONFIGURATION_CODE_LOAD_WEIGHT_USED);
    historySerieConfigurations.push({
      configurationUuid: loadWeightConfigurationUuid,
      value: loadWeightUsed.toString(),
    });

    return historySerieConfigurations;
  };

  return { runSave: save };
};
